const EN = {
   "Company": "Company",
   "About Us": "About Us",
   "Advantages": "Advantages",
   "AI Powered": "AI Powered",
   "Products": "Products",
   "Forex Exchange": "Forex Exchange",
   "Precious Metal": "Precious Metal",
   "Crude Oil": "Crude Oil",
   "Index": "Index",
   "Contract For Difference": "Contract For Difference",
   "Trading": "Trading",
   "VCG World Pool": "VCG World Pool",
   "Account Type": "Account Type",
   "Tools": "Tools",
   "Market News": "Market News",
   "CTrader": "CTrader",
   "Support": "Support",
   "Contact Us": "Contact Us",
   "FAQS": "FAQS",
   "Login": "Login",
   "Sign Up": "Sign Up",

   "Ready to get started?": "Ready to get started?",
   "Get Started": "Get Started",
   "Contact VCG": "Contact VCG",
   "Enter Your Email Address": "Enter Your Email Address",

   "Invest Wisely,": "Invest Wisely,",
   "Trade Profitably": "Trade Profitably",
   "with our fund management solutions": "with our fund management solutions",
   "Why vanguard Capital Group": "Why vanguard Capital Group",
   "Discover the advantages of trading with Vanguard Capital Group and embark on a voyage to financial success": "Discover the advantages of trading with Vanguard Capital Group and embark on a voyage to financial success",
   "Instant Buying & Selling Quotation": "Instant Buying & Selling Quotation",
   "Replicable transaction mode": "Replicable transaction mode",
   "intelligent expert adviser (ea)function": "intelligent expert adviser (ea)function",
   "clear account status": "clear account status",
   "multi-terminal system trading": "multi-terminal system trading",
   "advanced chatting tools": "advanced chatting tools",
   "what our clients says": "what our clients says",
   "Read our reviews To find out more about us": "Read our reviews To find out more about us",
   "All is working well. Only one week in and i very pleased to have joined VCG.": "All is working well. Only one week in and i very pleased to have joined VCG.",
   "Our Account Type": "Our Account Type",
   "Our account types cater to traders of all levels, providing customized solutions to match individual trading goals and requirements": "Our account types cater to traders of all levels, providing customized solutions to match individual trading goals and requirements",
   "Explorer": "Explorer Plan",
   "Adventurer Plan": "Adventurer Plan",
   "This account is perfect for beginner traders who are just starting out. With low minimum deposits and competitive spreads, this account type provides an accessible and affordable way to enter the world of forex trading.": "This account is perfect for beginner traders who are just starting out. With low minimum deposits and competitive spreads, this account type provides an accessible and affordable way to enter the world of forex trading.",
   "Adventurer Plan is perfect for experienced traders who demand the best and provides everything you need to stay ahead of the curve. With our team of expert traders on hand, you can be sure you're getting the most out of your trading experience.": "The Adventurer Package will offer even more options and features to help you succeed in forex trading. Stay tuned for updates on this new package and be the first to know when it becomes available.",
   "Read More": "Read More",
   "Powerful": "Powerful",
   "Trading Terminals": "Trading Terminals",
   "Enjoy Zero Commission": 'Enjoy "Zero" Commission',
   "We are a professional forex custody broker committed to providing investors with safe, stable, and efficient services. We are pleased to announce that our platform does not charge any commission fees. We believe that this will make our platform more attractive and enable investors to trade more easily. Our platform uses the most advanced technology and security measures to ensure the protection of all users' funds and trading data. Our team has extensive experience and professional knowledge to provide you with professional investment advice and support. If you have any questions or need assistance, please feel free to contact our customer service team. Thank you for choosing our platform and we wish you a happy trading experience!": "We are a professional forex custody broker committed to providing investors with safe, stable, and efficient services. We are pleased to announce that our platform does not charge any commission fees. We believe that this will make our platform more attractive and enable investors to trade more easily. Our platform uses the most advanced technology and security measures to ensure the protection of all users' funds and trading data. Our team has extensive experience and professional knowledge to provide you with professional investment advice and support. If you have any questions or need assistance, please feel free to contact our customer service team. Thank you for choosing our platform and we wish you a happy trading experience!",
   "Start Your ": "Start Your ",
   "Trading ": "Trading ",
   "Journey With ": "Journey With ",
   "An ": "An ",
   "Award Winning ": "Award Winning ",
   "Broker": "Broker",

   "MANAGED PORTFOLIOS": "MANAGED PORTFOLIOS",
   "Let The expert trade for you": "Let The expert trade for you",
   "Invest in a portfolio managed by experts to target the highest long-term returns for you risk appetite": "Invest in a portfolio managed by experts to target the highest long-term returns for you risk appetite",
   "Vanguard Capital Group, was established in 2005 and we are a professional and advanced forex broker. We have a total of 500,000+ users. Our mission as a broker is to provide our clients with a secure and reliable trading environment, while also offering exceptional customer service and competitive pricing.": "Vanguard Capital Group, was established in 2005 and we are a professional and advanced forex broker. We have a total of 500,000+ users. Our mission as a broker is to provide our clients with a secure and reliable trading environment, while also offering exceptional customer service and competitive pricing.",
   "As we continue to grow and expand our services, our future development goals include a focus on continuous innovation and the development of new technologies to improve our clients' trading experience. We are committed to staying ahead of the curve and ensuring that our clients have access to the latest tools and resources in the industry. At Vanguard Capital Group, we are dedicated to providing our clients with the best possible trading experience and helping them achieve their financial goals.": "As we continue to grow and expand our services, our future development goals include a focus on continuous innovation and the development of new technologies to improve our clients' trading experience. We are committed to staying ahead of the curve and ensuring that our clients have access to the latest tools and resources in the industry. At Vanguard Capital Group, we are dedicated to providing our clients with the best possible trading experience and helping them achieve their financial goals.",
   "Our Vision & Mission": "Our Vision & Mission",
   "Vision": "Vision",
   "Become an internationally leading intelligent investment management company": "Become an internationally leading intelligent investment management company",
   "Provide efficient, safe, and intelligent asset management services to global investors.": "Provide efficient, safe, and intelligent asset management services to global investors.",
   "Mission": "Mission",
   "Help investors achieve long-term and stable wealth growth": "Help investors achieve long-term and stable wealth growth",
   "Provide intelligent investment concepts and innovative technologies": "Provide intelligent investment concepts and innovative technologies",
  
   "Why choose vanguard Capital Group": "Why choose vanguard Capital Group",
   "Personalized Investment Strategies": "Personalized Investment Strategies",
   "We understand that every investor has unique needs and goals, which is why we offer personalized investment strategies tailored to each client's specific situation. We take the time to get to know our clients and their financial goals, so that we can help them make the best investment decisions for their individual needs.": "We understand that every investor has unique needs and goals, which is why we offer personalized investment strategies tailored to each client's specific situation. We take the time to get to know our clients and their financial goals, so that we can help them make the best investment decisions for their individual needs.",
   "Secure & Reliable Trading Environment": "Secure & Reliable Trading Environment", 
   "At Vanguard Capital Group, we take the security and reliability of our trading platform very seriously. We use the latest technology and security measures to ensure that our clients' funds and personal information are always safe and protected.": "At Vanguard Capital Group, we take the security and reliability of our trading platform very seriously. We use the latest technology and security measures to ensure that our clients' funds and personal information are always safe and protected.", 
   "Exceptional Customer Service": "Exceptional Customer Service",
   "Our commitment to exceptional customer service is what sets us apart from the competition. We're always here to answer any questions and address any concerns our clients may have, and we're always striving to provide the best possible experience for our clients.": "Our commitment to exceptional customer service is what sets us apart from the competition. We're always here to answer any questions and address any concerns our clients may have, and we're always striving to provide the best possible experience for our clients.", 
   "Exceptional Investment Expertise": "Exceptional Investment Expertise",
   "Our team of investment experts has years of experience in the industry, and we're always staying up-to-date on the latest trends and market movements. We use this expertise to help guide our clients in their investment decisions and to help them achieve their financial goals.": "Our team of investment experts has years of experience in the industry, and we're always staying up-to-date on the latest trends and market movements. We use this expertise to help guide our clients in their investment decisions and to help them achieve their financial goals.",
   "Powerful AI Trading": "Powerful AI Trading", 
   "Our advanced artificial intelligence trading system is a powerful tool that helps us make smarter investment decisions. By leveraging data and algorithms, we can analyze market trends and make predictions about where the market is headed. This allows us to make informed decisions and execute trades quickly and efficiently.": "Our advanced artificial intelligence trading system is a powerful tool that helps us make smarter investment decisions. By leveraging data and algorithms, we can analyze market trends and make predictions about where the market is headed. This allows us to make informed decisions and execute trades quickly and efficiently.",
   "Competitive Price": "Competitive Price",
   "We understand that cost is an important factor when it comes to investing, which is why we offer some of the most competitive pricing in the industry. We believe that everyone should have access to quality investment services, regardless of their budget.": "We understand that cost is an important factor when it comes to investing, which is why we offer some of the most competitive pricing in the industry. We believe that everyone should have access to quality investment services, regardless of their budget.",

   "AI - Powered Forex Trading": "AI - Powered Forex Trading",
   "Revolutionizing the way you trade": "Revolutionizing the way you trade",
   "Open Live Account": "Open Live Account",
   "Advantages of ": "Advantages of ",
   "AI - Powered Forex Automatic Trading ": "AI - Powered Forex Trading ",
   "Accuracy": "Accuracy",
   "AI-powered forex automatic trading can analyze more market data and make more accurate decisions based on data, improving the success rate of trades.": "AI-powered forex automatic trading can analyze more market data and make more accurate decisions based on data, improving the success rate of trades.",
   "Efficiency": "Efficiency",
   "AI-powered forex automatic trading can execute trades quickly, avoiding the impact of human factors, and can simultaneously monitor multiple markets to improve trading efficiency": "AI-powered forex automatic trading can execute trades quickly, avoiding the impact of human factors, and can simultaneously monitor multiple markets to improve trading efficiency",
   "Risk Management": "Risk Management",
   "AI-powered forex automatic trading can execute trades based on preset risk management rules, avoiding the risks associated with emotional trading and decision-making errors": "AI-powered forex automatic trading can execute trades based on preset risk management rules, avoiding the risks associated with emotional trading and decision-making errors",
   "Adaptability": "Adaptability",
   "AI-powered forex automatic trading can adjust its trading strategy to market changes, maintaining its adaptability and effectiveness.": "AI-powered forex automatic trading can adjust its trading strategy to market changes, maintaining its adaptability and effectiveness.",
   "AI-Powered Forex ": "AI-Powered Forex ",
   "Automatic Trading": "Automatic Trading",
   "AI-powered forex automatic trading is a revolutionary technological advancement that offers investors an efficient, accurate, and risk-controlled trading experience. If you have any questions or need assistance regarding AI-powered forex automatic trading, please contact our customer service team, and we will be happy to assist you.": "AI-powered forex automatic trading is a revolutionary technological advancement that offers investors an efficient, accurate, and risk-controlled trading experience. If you have any questions or need assistance regarding AI-powered forex automatic trading, please contact our customer service team, and we will be happy to assist you.",
   "Start Trading Now": "Start Trading Now",

   "Forex Exchange - Trade": "Forex Exchange - Trade",
   "Global currencies with Ease": "Global currencies with Ease",
   "Invest in the largest financial market in the world": "Invest in the largest financial market in the world",
   "What Is ": "What Is ",
   "Foreign Exchange?": "Foreign Exchange?",
   "Forex trading is the exchange of one country's currency with another. At present, the Foreign exchange rate is mainly affected by international trade, national economy and the investment flows policy. As a result, the foreign exchange market information is more transparent, liquidity is better and there are more fair prices. Buy or sell transactions can be completed for 5×24 hours. All the above advantages make Foreign Exchange transaction deeply affected by large investment institutions and investors.": "Forex trading is the exchange of one country's currency with another. At present, the Foreign exchange rate is mainly affected by international trade, national economy and the investment flows policy. As a result, the foreign exchange market information is more transparent, liquidity is better and there are more fair prices. Buy or sell transactions can be completed for 5×24 hours. All the above advantages make Foreign Exchange transaction deeply affected by large investment institutions and investors.",
   "At Vanguard ": "At Vanguard ",
   "Capital Group": "Capital Group",
   "We offer a range of Forex trading options to meet your unique investment needs and goals. Our advanced trading platform and expert advisors provide you with the tools and resources you need to make informed trading decisions and maximize your profits. Start trading global currencies with ease today.": "We offer a range of Forex trading options to meet your unique investment needs and goals. Our advanced trading platform and expert advisors provide you with the tools and resources you need to make informed trading decisions and maximize your profits. Start trading global currencies with ease today.",
   
   "Precious Metal Trading with ": "Precious Metal Trading with ",
   "Vanguard Capital Group": "Vanguard Capital Group",
   "We offers professional and transparent trading services for precious metal": "We offers professional and transparent trading services for precious metal",
   "Protect & Grow Your Wealth with ": "Protect & Grow Your Wealth with ",
   "Precious Metal Trading": "Precious Metal Trading",
   "Precious metals provide protection against currency inflation.": "Precious metals provide protection against currency inflation.",
   "Investors can benefit from price differences and maintain value during economic downturns.": "Investors can benefit from price differences and maintain value during economic downturns.",
   "Investors can make investment decisions based on their preferences.": "Investors can make investment decisions based on their preferences.",
   "We are committed to providing our clients with the advantages of precious metal trading and helping them achieve their financial goals. Our platform offers a safe and transparent environment for investors to trade precious metals, ensuring that they have access to the latest tools and resources in the industry.": "We are committed to providing our clients with the advantages of precious metal trading and helping them achieve their financial goals. Our platform offers a safe and transparent environment for investors to trade precious metals, ensuring that they have access to the latest tools and resources in the industry.",

   "Crude Oil Trading with": "Crude Oil Trading with",
   "Choose Vanguard Capital Group for safe, transparent, and flexible crude oil trading with low transaction costs and high capital utilization.": "Choose Vanguard Capital Group for safe, transparent, and flexible crude oil trading with low transaction costs and high capital utilization.",
   "Traded Crude Oil Futures with ": "Traded Crude Oil Futures with ",
   "Benefit from the growing volume of crude oil trading with Vanguard Capital Group's professional service platform.": "Benefit from the growing volume of crude oil trading with Vanguard Capital Group's professional service platform.",
   "Choose from a variety of crude oil products such as London Brent Crude Oil (UKOUSD) and US Crude Oil (USOUSD) based on your market understanding.": "Choose from a variety of crude oil products such as London Brent Crude Oil (UKOUSD) and US Crude Oil (USOUSD) based on your market understanding.",
   "Enjoy flexible trading with T+0 trading rules and easy-to-grasp supply and demand market information.": "Enjoy flexible trading with T+0 trading rules and easy-to-grasp supply and demand market information.",
   "We offers cost-effective crude oil trading with low spreads and transaction costs. Leverage transactions allow investors to maximize their returns and improve capital utilization. Understanding the market is made easy as crude oil prices are largely affected by supply and demand. Choose from a variety of crude oil products such as London Brent Crude Oil and US Crude Oil and experience flexible trading with T+0 trading rules.": "We offers cost-effective crude oil trading with low spreads and transaction costs. Leverage transactions allow investors to maximize their returns and improve capital utilization. Understanding the market is made easy as crude oil prices are largely affected by supply and demand. Choose from a variety of crude oil products such as London Brent Crude Oil and US Crude Oil and experience flexible trading with T+0 trading rules.",

   "Index Trading with": "Index Trading with",
   "Discover the derivative market of Index trading with Vanguard Capital Group.": "Discover the derivative market of Index trading with Vanguard Capital Group.",
   "Trade with ": "Trade with ",
   "Effective Risk Control & Flexible Leverage": "Effective Risk Control & Flexible Leverage",
   "Index Trading is a derivative market based on stock index trading, providing investors with an opportunity to speculate on the price fluctuations of relevant futures quoted by the exchange. At Vanguard Capital Group, we offer a variety of index CFDs with low margin and competitive spreads, allowing traders to effectively manage their risks while enjoying the benefits of flexible leverage.": "Index Trading is a derivative market based on stock index trading, providing investors with an opportunity to speculate on the price fluctuations of relevant futures quoted by the exchange. At Vanguard Capital Group, we offer a variety of index CFDs with low margin and competitive spreads, allowing traders to effectively manage their risks while enjoying the benefits of flexible leverage.",
   
   "CFD Trading with ": "CFD Trading with ",
   "Learn about CFD Trading with Vanguard Capital Group, a professional service platform that provides customers with safe, transparent trading opportunities.": "Learn about CFD Trading with Vanguard Capital Group, a professional service platform that provides customers with safe, transparent trading opportunities.",
   "Trade CFD with ": "Trade CFD with ",
   "Confidence": "Confidence",
   "CFDs are an emerging financial derivative that allows traders to speculate on the price of an underlying asset without actually owning it.": "CFDs are an emerging financial derivative that allows traders to speculate on the price of an underlying asset without actually owning it.",
   "THE ADVANTAGES OF CFD TRADING:": "THE ADVANTAGES OF CFD TRADING:",
   "Margin Trading": "Margin Trading",
   "Tradable in Rising and Falling Markets": "Tradable in Rising and Falling Markets",
   "T+0 Trading Mode": "T+0 Trading Mode",
   "Powerful Leverage Trading Model": "Powerful Leverage Trading Model",
   "Discover the benefits of Contract for Difference (CFD) trading with Vanguard Capital Group. CFDs are an emerging financial derivative traded at the price of a commodity, without the transaction of the commodity entity. With powerful leverage trading models and the ability to trade in rising and falling markets, CFD trading offers flexible opportunities for investors.": "Discover the benefits of Contract for Difference (CFD) trading with Vanguard Capital Group. CFDs are an emerging financial derivative traded at the price of a commodity, without the transaction of the commodity entity. With powerful leverage trading models and the ability to trade in rising and falling markets, CFD trading offers flexible opportunities for investors.",
   
   "VCG World Pool Benefit Graph & User Leaderboard": "VCG World Pool Benefit Graph & User Leaderboard",
   "Check out the VCG World Pool Benefit Graph and User Leaderboard to track your performance and see how you compare to other traders on the platform.": "Check out the VCG World Pool Benefit Graph and User Leaderboard to track your performance and see how you compare to other traders on the platform.",

   "Choose the perfect account type for your trading needs": "Choose the perfect account type for your trading needs",
   "Explorer Plan": "Explorer Plan",
   "Lock": "Lock",
   "1/2 Month": "1/2 Month",
   "1 Month": "1 Month",
   "3 Month": "3 Month",
   "6 Month": "6 Month",
   "9 Month": "9 Month",
   "Coming Soon": "Coming Soon",
   "90 and above": "100 USD and above",
   "150 and above": "150 USD and above",
   "300 and above": "300 USD and above",
   "500 and above": "500 USD and above",
   "5 USD": "5 USD",
   "15 USD": "15 USD",
   "Month": "Month",
   "Deposit": "Deposit",
   "ROI": "ROI",
   "Referral Bonus": "Referral Bonus",
   "Customer Profit": "Customer Profit",
   "Profit Sharing": "Profit Sharing",
   "Management Fees": "Management Fees",
   "Withdrawal Period": "Withdrawal Period",
   "Within 3 Working Days": "1st to 5th Of Every Months",
   "Start Trading": "Start Trading",
   "Start trading with Vanguard Capital Group in 4 Steps": "Start trading with Vanguard Capital Group in 4 Steps",
   "Register": "Register",
   "Verify": "Verify",
   "Trade": "Trade",

   "View All News": "View All News",
   "USD/CHF - Swiss franc climb higher": "USD/CHF - Swiss franc climb higher",
   "Traders and investors use trading news to make informed decisions about buying and selling securities, currencies, commodities, and other financial instruments.": "Traders and investors use trading news to make informed decisions about buying and selling securities, currencies, commodities, and other financial instruments.",
   "10 hours": "10 hours",

   "cTrader is a complete trading platform solution for Forex and CFD brokers to offer their traders. The platform is packed with a full range of features to cater to each and every investment preference imaginable.": "cTrader is a complete trading platform solution for Forex and CFD brokers to offer their traders. The platform is packed with a full range of features to cater to each and every investment preference imaginable.",
   "Trading with Vanguard Capital Group CTrader": "Trading with Vanguard Capital Group CTrader",
   "A Premium Charting and Manual Trading PlatformcTrader is a leading multi-asset Forex and CFD trading platform, offering rich charting tools, advanced order types, level II pricing, and fast entry and execution. With a stunning user interface, it’s connected to the most sophisticated backend technology, and made available on multiple": "A Premium Charting and Manual Trading PlatformcTrader is a leading multi-asset Forex and CFD trading platform, offering rich charting tools, advanced order types, level II pricing, and fast entry and execution. With a stunning user interface, it’s connected to the most sophisticated backend technology, and made available on multiple",
   "CTrader Web": "CTrader Web",
   "with CTrader Web, users can access all the features of cTrader from any popular browser without the need for installation. It's a fast and easy way to trade on desktops running Windows, Mac, or Linux operating systems.": "with CTrader Web, users can access all the features of cTrader from any popular browser without the need for installation. It's a fast and easy way to trade on desktops running Windows, Mac, or Linux operating systems.",
   "Explore Now": "Explore Now",
   "CTrader Mobile": "CTrader Mobile",
   "Traders can download the native cTrader app from either the App Store or Google Play Store for their iOS or Android mobile devices. The tablet-optimized apps offer a variety of key features for effective on-the-go trading and analysis from anywhere in the world.": "Traders can download the native cTrader app from either the App Store or Google Play Store for their iOS or Android mobile devices. The tablet-optimized apps offer a variety of key features for effective on-the-go trading and analysis from anywhere in the world.",

   "5*24 hours online customer service providing online support & assistance": "7/24 hours online customer service providing online support & assistance",
   "Name": 'Name',
   "Your Name": "Your Name",
   "Your Email": "Your Email",
   "Phone Number": "Phone Number",
   "Your phone number": "Your phone number",
   "Submit": "Submit",

   "Get answer to all your questions about trading, account management, regulations and more with our comprehensive FAQ section": "Get answer to all your questions about trading, account management, regulations and more with our comprehensive FAQ section",
   "How to start trading?": "How to start trading?",
   "VCG account type": "VCG account type",
   "Registering for a VCG account is just the first step in your trading journey! There are many activities you can participate in after registration. To learn more about VCG's range of services, please click here. (Include Referral Info link)": "Registering for a VCG account is just the first step in your trading journey! There are many activities you can participate in after registration. To learn more about VCG's range of services, please click here. (Include Referral Info link)",
   "Maximum investment limit for the plans": "Maximum investment limit for the plans",
   "There is no limit to the investment amount, clients can freely choose the investment amount according to their financial strength and investment needs. The unlimited investment amount means that clients can invest any amount of funds into investment projects to obtain higher investment returns and profits. At the same time, clients also need to make prudent investment decisions based on their risk tolerance and financial planning to ensure a balance between investment risks and returns": "There is no limit to the investment amount, clients can freely choose the investment amount according to their financial strength and investment needs. The unlimited investment amount means that clients can invest any amount of funds into investment projects to obtain higher investment returns and profits. At the same time, clients also need to make prudent investment decisions based on their risk tolerance and financial planning to ensure a balance between investment risks and returns",
   "Minimum deposit amount": "Minimum deposit amount",
   "The minimum deposit amount varies depending on the company, account type, and investment plan selected. It is important to remind clients that the principal invested in the investment plan will directly determine the rate of return. If clients have a specific amount they would like to earn, it is recommended to seek the assistance of an agent.": "The minimum deposit amount varies depending on the company, account type, and investment plan selected. It is important to remind clients that the principal invested in the investment plan will directly determine the rate of return. If clients have a specific amount they would like to earn, it is recommended to seek the assistance of an agent.",
   "How do I deposit and withdraw funds?": "How do I deposit and withdraw funds?",
   "You can use various payment methods to deposit and withdraw your funds, such as bank transfers, electronic payments, and digital currencies, etc. You can choose your preferred payment method in your trading account and follow the prompts to complete the operation": "You can use various payment methods to deposit and withdraw your funds, such as bank transfers, electronic payments, and digital currencies, etc. You can choose your preferred payment method in your trading account and follow the prompts to complete the operation",
   "What is investment net value?": "What is investment net value?",
   "Investment net value is the current value of the funds in an investment, which is the sum of the initial investment amount and the results of all orders (minus paid commissions). Investment net value = investment amount + investment returns - paid commissions. For example: If an investor invests $1,000 with a commission rate of 20%, and after three months the results of all orders is $200, with a commission of $40 paid to the investment manager, the investment net value is $1,160 ($1,000 + $200 - $40).": "Investment net value is the current value of the funds in an investment, which is the sum of the initial investment amount and the results of all orders (minus paid commissions). Investment net value = investment amount + investment returns - paid commissions. For example: If an investor invests $1,000 with a commission rate of 20%, and after three months the results of all orders is $200, with a commission of $40 paid to the investment manager, the investment net value is $1,160 ($1,000 + $200 - $40).",
   "What should i do if the trading platform is not functioning properly?": "What should i do if the trading platform is not functioning properly?",
   "If you encounter any problems while using the trading platform, please contact our technical support team. Our technical support staff will help you resolve the issue as soon as possible.": "If you encounter any problems while using the trading platform, please contact our technical support team. Our technical support staff will help you resolve the issue as soon as possible.",
   "What should i do if i am unable to log in to the trading platform?": "What should i do if i am unable to log in to the trading platform?",
   "If you are unable to log in to the trading platform, please ensure that your username and password are correct. If you still cannot log in, try resetting your password. If the issue persists, please contact our customer service team for further assistance.": "If you are unable to log in to the trading platform, please ensure that your username and password are correct. If you still cannot log in, try resetting your password. If the issue persists, please contact our customer service team for further assistance.",
   "What should i do if i have lost my trading password?": "What should i do if i have lost my trading password?",
   "Please contact our customer service team and they will guide you through the password reset process. After you have been verified, we will send a password reset link to your registered email address.": "Please contact our customer service team and they will guide you through the password reset process. After you have been verified, we will send a password reset link to your registered email address.",
   "How can i change my trading account password?": "How can i change my trading account password?",
   "You can change your trading account password in the account settings of the trading platform. The specific process may vary depending on the trading platform. Please refer to the trading platform's documentation or contact customer service for assistance": "You can change your trading account password in the account settings of the trading platform. The specific process may vary depending on the trading platform. Please refer to the trading platform's documentation or contact customer service for assistance",
   "Is my trading account protected?": "Is my trading account protected?",
   "Brokers provide customers with measures for fund protection and account security. These measures may include fund segregation, multi-factor authentication, etc. If you have any questions about the protection measures for your trading account, you can consult customer service representatives": "Brokers provide customers with measures for fund protection and account security. These measures may include fund segregation, multi-factor authentication, etc. If you have any questions about the protection measures for your trading account, you can consult customer service representatives",
   "How can I change my account information?": "How can I change my account information?",
   "If you need to change your account information, such as email address, phone number, or bank account information, you can log in to your trading platform or contact customer service representatives to make the changes.": "If you need to change your account information, such as email address, phone number, or bank account information, you can log in to your trading platform or contact customer service representatives to make the changes.",

   "Access the derivative market based on stock index trading.": "Access the derivative market based on stock index trading.",
   "The index CFDs reflect the price fluctuations of relevant futures quoted by the exchange.": "The index CFDs reflect the price fluctuations of relevant futures quoted by the exchange.",
   "Low margin and low cost make Index Trading cost-effective.": "Low margin and low cost make Index Trading cost-effective.",
   "Enjoy competitive spread and a variety of trading samples.": "Enjoy competitive spread and a variety of trading samples.",
   "Flexible leverage allows investors to maximize their returns.": "Flexible leverage allows investors to maximize their returns.",
   "$800 & Above": "$800 & Above",

   "I have been working with this company for several months now and I couldn't be happier with the results. Their new project with the early bird bonus is a game-changer and has helped me earn even more commission. Highly recommended!": "I have been working with this company for several months now and I couldn't be happier with the results. Their new project with the early bird bonus is a game-changer and has helped me earn even more commission. Highly recommended!",
   "As a new member, I was a bit hesitant to join this company, but I'm glad I did. Their new project with the early bird bonus is a fantastic opportunity for anyone looking to make some extra money. Plus, their support team is always available to answer any questions I have.": "As a new member, I was a bit hesitant to join this company, but I'm glad I did. Their new project with the early bird bonus is a fantastic opportunity for anyone looking to make some extra money. Plus, their support team is always available to answer any questions I have.",
   "I've been in the trading industry for years and I've never come across a project like this. The AI system has helped me make some smart trades. I highly recommend this company to anyone looking to boost their earnings.": "I've been in the trading industry for years and I've never come across a project like this. The AI system has helped me make some smart trades. I highly recommend this company to anyone looking to boost their earnings.",
   "The early bird bonus really caught my attention. I decided to give it a try and I'm so glad I did. Not only did I earn more commission, but I also gained valuable knowledge about trading. Thank you, VCG!": "The early bird bonus really caught my attention. I decided to give it a try and I'm so glad I did. Not only did I earn more commission, but I also gained valuable knowledge about trading. Thank you, VCG!",
   "If you're looking for a reliable and profitable project, look no further than VCG. Their early bird bonus is an incredible offer and their AI system is second to none. Plus, their team of agents is always willing to lend a helping hand. Highly recommended!": "If you're looking for a reliable and profitable project, look no further than VCG. Their early bird bonus is an incredible offer and their AI system is second to none. Plus, their team of agents is always willing to lend a helping hand. Highly recommended!"
}

export default EN;