import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ParticlesBubble from '../Particles';

function ContractDifferent(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className='product'>
                <div className='headerSection' id="contract">
                    <div className='row justify-content-center justify-content-lg-start w-100 mx-0'>
                        <div className='col-12 col-lg-1' />
                        <div className='col-12 col-lg-5 text-center text-lg-left'>


                            <div className={props.lang === "ZH_CN" ? 'd-none' : 'd-block'}>
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "CFD Trading with ")}</span><br />
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Vanguard Capital Group")}</span>
                            </div>
                            <div className={props.lang === "ZH_CN" ? 'd-block' : 'd-none'}>
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "CFD Trading with ")}</span><br />
                            </div>


                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Learn about CFD Trading with Vanguard Capital Group, a professional service platform that provides customers with safe, transparent trading opportunities.")}</span>
                            </div>
                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Open Live Account")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection vh-min-100 d-flex align-items-center justify-content-center bg-white'>
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-8 col-lg-6 text-center mb-5'>
                            <img src={require('../../assets/img/product/contractDifferent/detailsImg.png').default} className="img-fluid productDetailsImgSize mx-auto" />
                        </div>
                        <div className='col-12 col-md-10 col-lg-5 mb-4 text-center text-lg-left'>
                            <span className='font-EN-Bold sectionProductTitle text-blue'>{translate(props.lang, "Trade CFD with ")}</span>
                            <span className='font-EN-Bold sectionProductTitle text-purple3'>{translate(props.lang, "Confidence")}</span>

                            <div className='pt-4'>
                                <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "CFDs are an emerging financial derivative that allows traders to speculate on the price of an underlying asset without actually owning it.")}</span>
                            </div>

                            <div className='pt-4'>
                                <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "THE ADVANTAGES OF CFD TRADING:")}</span>
                                <div className='pt-4'>
                                    <ul className='text-left'>
                                        <li className='mb-3'>
                                            <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Margin Trading")}</span>
                                        </li>
                                        <li className='mb-3'>
                                            <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Tradable in Rising and Falling Markets")}</span>
                                        </li>
                                        <li className='mb-3'>
                                            <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "T+0 Trading Mode")}</span>
                                        </li>
                                        <li className='mb-3'>
                                            <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Powerful Leverage Trading Model")}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection vh-min-100 d-flex align-items-center justify-content-center'>
                    <ParticlesBubble />
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-10 col-lg-9 text-center'>
                            <span className='font-EN-Bold sectionTitle text-purple-light2'>{translate(props.lang, "At Vanguard ")}</span>
                            <span className='font-EN-Bold sectionTitle text-purple3'>{translate(props.lang, "Capital Group")}</span>

                            <div className='pt-5'>
                                <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Discover the benefits of Contract for Difference (CFD) trading with Vanguard Capital Group. CFDs are an emerging financial derivative traded at the price of a commodity, without the transaction of the commodity entity. With powerful leverage trading models and the ability to trade in rising and falling markets, CFD trading offers flexible opportunities for investors.")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 mx-auto cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Start Trading Now")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(ContractDifferent);
