import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import { Input } from "reactstrap";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import ReactHtmlParser from 'react-html-parser';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { FaAngleRight, FaMinus, FaPlus } from 'react-icons/fa';

function FAQ(props) {
    const [selectedContent2, setSelectedContent2] = React.useState(0);

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const faqAccordion = [
        {
            title: translate(props.lang, 'How to start trading?'),
            EN_content: `
            <span className="accordionContent"><b>Step 1 - Register for a VCG account</b></span><br />
            <span className="accordionContent">If you haven't created a personal area, click this link to register for a VCG account:
                After creating a personal area, the system will automatically create a real trading account and a demo
                trading account (both MT5 accounts) for you. You can view these accounts by clicking on the account
                section in your personal area
            </span> <br /><br />
            <span className="accordionContent"><b>Step 2 -  Verify personal information</b></span><br />
            <span className="accordionContent">After creating a personal area, it is necessary to fill out your economic profile and submit
                proof of identity and residence documents. This is a necessary measure to prevent fraud and protect
                VCG customers from identity theft.
            </span> <br /><br />
            <span className="accordionContent"><b>Step 3 -  Make the first deposit</b></span><br />
            <span className="accordionContent">VCG partners with global payment providers to offer our customers easier and more
                convenient deposit and withdrawal services. To see available payment methods, please visit the help
                center.
            </span> <br /><br />
            <span className="accordionContent"><b>Step 4 -  Trading Platform</b></span><br />
            <span className="accordionContent">Customers can use the most popular trading platform - CTrader - to trade on VCG. You can
                click here to download CTrader. (Include software download link)
            </span> <br /><br />
            <span className="accordionContent"><b>Step 5 -  Choose a package to start trading</b></span><br />
            <span className="accordionContent">Now that you have successfully deposited your first funds into your account and set up your
                trading terminal, the only thing left to do is to choose an investment package. Don't wait any longer,
                let's get started.
            </span> <br /><br />
            <span className="accordionContent"><b>Step 6 -  Registration completed</b></span><br />
            <span className="accordionContent">Registering for a VCG account is just the first step in your trading journey! There are many
                activities you can participate in after registration. To learn more about VCG's range of services, please
                click here. (Include Referral Info link)
            </span> <br /><br />`,

            ZH_content: `
            <span className="accordionContent"><b>第1步 - 注册一个 VCG 帐户</b></span><br />
            <span className="accordionContent">如果您还没有创建个人专区，请点击此链接注册一个VCG账号：
            创建个人专区后，系统会自动创建真实交易账户和模拟账户
            交易账户（均为 MT5 账户）。您可以通过单击帐户来查看这些帐户
            您个人区域中的部分
            </span> <br /><br />
            <span className="accordionContent"><b>第2步 - 验证个人信息</b></span><br />
            <span className="accordionContent">创建个人专区后，需要填写您的经济概况并提交
            身份证明和居住证件。这是防止欺诈和保护的必要措施
            身份盗用的 VCG 客户。
            </span> <br /><br />
            <span className="accordionContent"><b>第3步 - 进行第一笔存款</b></span><br />
            <span className="accordionContent">VCG 与全球支付提供商合作，为我们的客户提供更轻松、更多的服务
            便捷的存取款服务。要查看可用的付款方式，请访问帮助中心。
            </span> <br /><br />
            <span className="accordionContent"><b>第4步 - 交易平台</b></span><br />
            <span className="accordionContent">客户可以使用最流行的交易平台——CTrader——在VCG上进行交易。你可以
            单击此处下载 CTrader。 （附上软件下载链接）
            </span> <br /><br />
            <span className="accordionContent"><b>第5步 - 选择套餐开始交易</b></span><br />
            <span className="accordionContent">现在您已成功将第一笔资金存入您的帐户并设置您的
            交易终端，剩下要做的就是选择投资组合。不要再等了，让我们开始吧。
            </span> <br /><br />
            <span className="accordionContent"><b>第6步 - 注册完成</b></span><br />
            <span className="accordionContent">注册VCG账户只是您交易之旅的第一步！有许多
            报名后可参加的活动。要了解有关 VCG 服务范围的更多信息，请
            点击这里。 （包括推荐信息链接）
            </span> <br /><br />`
        },
        {
            title: translate(props.lang, 'VCG account type'),
            EN_content: translate(props.lang, "Registering for a VCG account is just the first step in your trading journey! There are many activities you can participate in after registration. To learn more about VCG's range of services, please click here. (Include Referral Info link)"),
        },
        {
            title: translate(props.lang, 'Maximum investment limit for the plans'),
            EN_content: translate(props.lang, "There is no limit to the investment amount, clients can freely choose the investment amount according to their financial strength and investment needs. The unlimited investment amount means that clients can invest any amount of funds into investment projects to obtain higher investment returns and profits. At the same time, clients also need to make prudent investment decisions based on their risk tolerance and financial planning to ensure a balance between investment risks and returns"),
        },
        {
            title: translate(props.lang, 'Minimum deposit amount'),
            EN_content: translate(props.lang, "The minimum deposit amount varies depending on the company, account type, and investment plan selected. It is important to remind clients that the principal invested in the investment plan will directly determine the rate of return. If clients have a specific amount they would like to earn, it is recommended to seek the assistance of an agent."),
        },
        {
            title: translate(props.lang, 'How do I deposit and withdraw funds?'),
            EN_content: translate(props.lang, "You can use various payment methods to deposit and withdraw your funds, such as bank transfers, electronic payments, and digital currencies, etc. You can choose your preferred payment method in your trading account and follow the prompts to complete the operation"),
        },
        {
            title: translate(props.lang, 'What is investment net value?'),
            EN_content: translate(props.lang, "Investment net value is the current value of the funds in an investment, which is the sum of the initial investment amount and the results of all orders (minus paid commissions). Investment net value = investment amount + investment returns - paid commissions. For example: If an investor invests $1,000 with a commission rate of 20%, and after three months the results of all orders is $200, with a commission of $40 paid to the investment manager, the investment net value is $1,160 ($1,000 + $200 - $40)."),
        },
        {
            title: translate(props.lang, 'What should i do if the trading platform is not functioning properly?'),
            EN_content: translate(props.lang, "If you encounter any problems while using the trading platform, please contact our technical support team. Our technical support staff will help you resolve the issue as soon as possible."),
        },
        {
            title: translate(props.lang, 'What should i do if i am unable to log in to the trading platform?'),
            EN_content: translate(props.lang, "If you are unable to log in to the trading platform, please ensure that your username and password are correct. If you still cannot log in, try resetting your password. If the issue persists, please contact our customer service team for further assistance."),
        },
        {
            title: translate(props.lang, 'What should i do if i have lost my trading password?'),
            EN_content: translate(props.lang, "Please contact our customer service team and they will guide you through the password reset process. After you have been verified, we will send a password reset link to your registered email address."),
        },
        {
            title: translate(props.lang, 'How can i change my trading account password?'),
            EN_content: translate(props.lang, "You can change your trading account password in the account settings of the trading platform. The specific process may vary depending on the trading platform. Please refer to the trading platform's documentation or contact customer service for assistance"),
        },
        {
            title: translate(props.lang, 'Is my trading account protected?'),
            EN_content: translate(props.lang, "Brokers provide customers with measures for fund protection and account security. These measures may include fund segregation, multi-factor authentication, etc. If you have any questions about the protection measures for your trading account, you can consult customer service representatives"),
        },
        {
            title: translate(props.lang, 'How can I change my account information?'),
            EN_content: translate(props.lang, "If you need to change your account information, such as email address, phone number, or bank account information, you can log in to your trading platform or contact customer service representatives to make the changes."),
        },
    ]

    return (
        <>
            <IndexNavbar />
            <div className='support'>
                <div className='headerSectionFAQ'>
                    <div>
                        <div className='row justify-content-center align-items-start w-100 mx-0 py-5 mt-5 mt-md-4'>
                            <div className='col-12 col-md-11 col-lg-6 text-center py-5'>
                                <span className='font-EN-Bold headerTitle text-purple'>{translate(props.lang, "FAQS")}</span><br />
                                <div className='pt-3'>
                                    <span className='font-EN-Regular headerSubTitle text-purple'>{translate(props.lang, "Get answer to all your questions about trading, account management, regulations and more with our comprehensive FAQ section")}</span>
                                </div>
                            </div>
                        </div>

                        <div className='row justify-content-center align-items-start w-100 mx-0 pb-5'>
                            <div className='col-12 col-md-6 col-lg-5 text-left mb-4'>
                                <Accordion preExpanded={[0]} className="pt-2 text-left">
                                    {faqAccordion.map((item, i) => (
                                        i >= 6 ? "" :
                                            <AccordionItem key={i} uuid={i} className={i === selectedContent2 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent2(i)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10">
                                                                <div className="">
                                                                    <span className={i === selectedContent2 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        {item.title}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2">
                                                                <div className="">
                                                                    <FaMinus className="h3 my-0 ml-3 accordionIcon" style={{ display: i === selectedContent2 ? "block" : "none" }} />
                                                                    <FaPlus className="h3 my-0 ml-3 accordionIcon" style={{ display: i === selectedContent2 ? "none" : "block" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel style={{ display: i === selectedContent2 ? "block" : "none" }}>
                                                    <div className="row justify-content-start align-items-center pt-3">
                                                        <div className="col-12">
                                                            <span className="accordionContent">
                                                                {props.lang === "ZH_CN" && item.ZH_content && item.ZH_content ?
                                                                    ReactHtmlParser(item.ZH_content) :
                                                                    ReactHtmlParser(item.EN_content)
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>

                            <div className='col-12 col-md-6 col-lg-5 text-left mb-4'>
                                <Accordion preExpanded={[0]} className="pt-2 text-left">
                                    {faqAccordion.map((item, i) => (
                                        i < 6 ? "" :
                                            <AccordionItem key={i} uuid={i} className={i === selectedContent2 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent2(i)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10">
                                                                <div className="">
                                                                    <span className={i === selectedContent2 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        {item.title}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2">
                                                                <div className="">
                                                                    <FaMinus className="h3 my-0 ml-3 accordionIcon" style={{ display: i === selectedContent2 ? "block" : "none" }} />
                                                                    <FaPlus className="h3 my-0 ml-3 accordionIcon" style={{ display: i === selectedContent2 ? "none" : "block" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel style={{ display: i === selectedContent2 ? "block" : "none" }}>
                                                    <div className="row justify-content-start align-items-center pt-3">
                                                        <div className="col-12">
                                                            <span className="accordionContent">
                                                                {props.lang === "ZH_CN" && item.ZH_content && item.ZH_content ?
                                                                    ReactHtmlParser(item.ZH_content) :
                                                                    ReactHtmlParser(item.EN_content)
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>

                            <div className='col-12'>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(FAQ);
