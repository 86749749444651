import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ParticlesBubble from '../Particles';

function IndexProduct(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className='product'>
                <div className='headerSection' id="index">
                    <div className='row justify-content-center justify-content-lg-start w-100 mx-0'>
                        <div className='col-12 col-lg-1' />
                        <div className='col-12 col-lg-5 text-center text-lg-left'>
                            <div className={props.lang === "ZH_CN" ? 'd-none' : 'd-block'}>
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Index Trading with")}</span><br />
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Vanguard Capital Group")}</span>
                            </div>
                            <div className={props.lang === "ZH_CN" ? 'd-block' : 'd-none'}>
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Index Trading with")}</span><br />
                            </div>

                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Discover the derivative market of Index trading with Vanguard Capital Group.")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Open Live Account")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection vh-min-100 d-flex align-items-center justify-content-center bg-white'>
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-8 col-lg-6 text-center mb-5'>
                            <img src={require('../../assets/img/product/index/detailsImg.png').default} className="img-fluid productDetailsImgSize mx-auto" />
                        </div>
                        <div className='col-12 col-md-10 col-lg-5 mb-4 text-center text-lg-left'>
                            <span className='font-EN-Bold sectionProductTitle text-blue'>{translate(props.lang, "Trade with ")}</span>
                            <span className='font-EN-Bold sectionProductTitle text-purple3'>{translate(props.lang, "Effective Risk Control & Flexible Leverage")}</span>

                            <div className='pt-4'>
                                <ul className='text-left'>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Access the derivative market based on stock index trading.")}</span>
                                    </li>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "The index CFDs reflect the price fluctuations of relevant futures quoted by the exchange.")}</span>
                                    </li>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Low margin and low cost make Index Trading cost-effective.")}</span>
                                    </li>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Enjoy competitive spread and a variety of trading samples.")}</span>
                                    </li>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Flexible leverage allows investors to maximize their returns.")}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection vh-min-100 d-flex align-items-center justify-content-center'>
                    <ParticlesBubble />
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-10 col-lg-9 text-center'>
                            <span className='font-EN-Bold sectionTitle text-purple-light2'>{translate(props.lang, "At Vanguard ")}</span>
                            <span className='font-EN-Bold sectionTitle text-purple3'>{translate(props.lang, "Capital Group")}</span>

                            <div className='pt-5'>
                                <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Index Trading is a derivative market based on stock index trading, providing investors with an opportunity to speculate on the price fluctuations of relevant futures quoted by the exchange. At Vanguard Capital Group, we offer a variety of index CFDs with low margin and competitive spreads, allowing traders to effectively manage their risks while enjoying the benefits of flexible leverage.")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 mx-auto cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Start Trading Now")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(IndexProduct);
