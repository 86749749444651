import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function VCG_Pools(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className='trading pt-5'>
                <div className='py-5 vcgPoolBg w-100'>
                    <div className='position-relative w-100 pt-5'>
                        <div className='row justify-content-center align-items-center w-100 mx-0 pt-5'>
                            <div className='col-12 col-md-11 col-lg-6 text-center mb-4'>
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "VCG World Pool Benefit Graph & User Leaderboard")}</span> <br /><br />
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Check out the VCG World Pool Benefit Graph and User Leaderboard to track your performance and see how you compare to other traders on the platform.")}</span>
                            </div>

                        </div>


                        <div className='row justify-content-center align-items-center mx-auto pb-5 scoreBoardPadding'>
                            <div className='col-12 col-md-12 col-lg-10'>
                                <img src={require('../../assets/img/trading/vcgPool/scoreBoard.png').default} className="img-fluid mx-auto w-100" />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(VCG_Pools);
