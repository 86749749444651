import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ParticlesBubble from '../Particles';

function ForexExchange(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className='product'>
                <div className='headerSection' id="forexExchange">
                    <div className='row justify-content-center justify-content-lg-start w-100 mx-0'>
                        <div className='col-12 col-lg-1' />
                        <div className='col-12 col-lg-11 text-center text-lg-left'>
                            <div className={props.lang === "ZH_CN" ? 'd-none' : 'd-block'}>
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Forex Exchange - Trade")}</span><br />
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Global currencies with Ease")}</span>
                            </div>
                            <div className={props.lang === "ZH_CN" ? 'd-block' : 'd-none'}>
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Forex Exchange - Trade")}</span><br />
                            </div>
                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Invest in the largest financial market in the world")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Open Live Account")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection vh-min-100 d-flex align-items-center justify-content-center bg-white'>
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-8 col-lg-6 text-center mb-5'>
                            <img src={require('../../assets/img/product/forexExchange/detailsImg.png').default} className="img-fluid productDetailsImgSize mx-auto" />
                        </div>
                        <div className='col-12 col-md-10 col-lg-5 mb-4 text-center text-lg-left'>
                            <span className='font-EN-Bold sectionProductTitle text-blue'>{translate(props.lang, "What Is ")}</span>
                            <span className='font-EN-Bold sectionProductTitle text-purple3'>{translate(props.lang, "Foreign Exchange?")}</span>

                            <div className='pt-4'>
                                <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Forex trading is the exchange of one country's currency with another. At present, the Foreign exchange rate is mainly affected by international trade, national economy and the investment flows policy. As a result, the foreign exchange market information is more transparent, liquidity is better and there are more fair prices. Buy or sell transactions can be completed for 5×24 hours. All the above advantages make Foreign Exchange transaction deeply affected by large investment institutions and investors.")}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection vh-min-100 d-flex align-items-center justify-content-center'>
                    <ParticlesBubble />
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-10 col-lg-9 text-center'>
                            <span className='font-EN-Bold sectionTitle text-purple-light2'>{translate(props.lang, "At Vanguard ")}</span>
                            <span className='font-EN-Bold sectionTitle text-purple3'>{translate(props.lang, "Capital Group")}</span>

                            <div className='pt-5'>
                                <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "We offer a range of Forex trading options to meet your unique investment needs and goals. Our advanced trading platform and expert advisors provide you with the tools and resources you need to make informed trading decisions and maximize your profits. Start trading global currencies with ease today.")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 mx-auto cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Start Trading Now")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(ForexExchange);
