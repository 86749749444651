import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import { FaAngleDown, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

import advantageIcon1 from "../../assets/img/company/advantages/advantage1.png";
import advantageIcon2 from "../../assets/img/company/advantages/advantage2.png";
import advantageIcon3 from "../../assets/img/company/advantages/advantage3.png";
import advantageIcon4 from "../../assets/img/company/advantages/advantage4.png";
import advantageIcon5 from "../../assets/img/company/advantages/advantage5.png";
import advantageIcon6 from "../../assets/img/company/advantages/advantage6.png";
import ParticlesBubble from 'views/Particles';
import Lottie from 'react-lottie-player'
import lottieJson from '../../assets/img/home/podium.json';

const advantagesArray = [
    { img: advantageIcon1, title: "Personalized Investment Strategies", desc: "We understand that every investor has unique needs and goals, which is why we offer personalized investment strategies tailored to each client's specific situation. We take the time to get to know our clients and their financial goals, so that we can help them make the best investment decisions for their individual needs." },
    { img: advantageIcon2, title: "Secure & Reliable Trading Environment", desc: "At Vanguard Capital Group, we take the security and reliability of our trading platform very seriously. We use the latest technology and security measures to ensure that our clients' funds and personal information are always safe and protected." },
    { img: advantageIcon3, title: "Exceptional Customer Service", desc: "Our commitment to exceptional customer service is what sets us apart from the competition. We're always here to answer any questions and address any concerns our clients may have, and we're always striving to provide the best possible experience for our clients." },
    { img: advantageIcon4, title: "Exceptional Investment Expertise", desc: "Our team of investment experts has years of experience in the industry, and we're always staying up-to-date on the latest trends and market movements. We use this expertise to help guide our clients in their investment decisions and to help them achieve their financial goals." },
    { img: advantageIcon5, title: "Powerful AI Trading", desc: "Our advanced artificial intelligence trading system is a powerful tool that helps us make smarter investment decisions. By leveraging data and algorithms, we can analyze market trends and make predictions about where the market is headed. This allows us to make informed decisions and execute trades quickly and efficiently." },
    { img: advantageIcon6, title: "Competitive Price", desc: "We understand that cost is an important factor when it comes to investing, which is why we offer some of the most competitive pricing in the industry. We believe that everyone should have access to quality investment services, regardless of their budget." },
]

function AccountType(props) {
    const [selectedMonth, setMonth] = React.useState(0);

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow robotNext p-2 cursor-pointer" id="nextArrow" onClick={onClick}>
                <FaAngleRight fontSize={window.innerWidth > 300 ? 30 : 18} color='#fff' />
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow robotPrev p-2 cursor-pointer" id="prevArrow" onClick={onClick}>
                <FaAngleLeft fontSize={window.innerWidth > 300 ? 30 : 18} color='#fff' />
            </div>
        );
    };

    const [imageIndex, setImageIndex] = React.useState(0);

    const settings = {
        dots: window.innerWidth > 677 ? false : false,
        infinite: true,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        swipe: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <IndexNavbar />
            <div className='trading'>
                <div className='' id="accountType">
                    <div className='row justify-content-center justify-content-lg-start w-100 mx-0'>
                        <div className='col-12 col-lg-1' />
                        <div className='col-12 col-md-10 col-lg-5 text-center text-lg-left'>
                            <div className=''>
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Account Type")}</span><br />
                                <div className='pt-3'>
                                    <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Choose the perfect account type for your trading needs")}</span>
                                </div>

                                <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                    <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                        <span className=''>{translate(props.lang, "Open Live Account")}</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='robotAccountBg w-100 py-5 py-lg-0'>
                    <div className='w-100 py-5 py-lg-0'>
                        <div className='row justify-content-center align-items-center mx-0 w-100 position-relative'>
                            <div className='col-11 col-lg-5 mb-5 order-2 order-lg-1'>
                                <div className='pt-0 pt-lg-5'>
                                    <span className='font-EN-Bold sectionAccountTitle text-white'>{translate(props.lang, "Our Account Type")}</span>

                                    <div className='d-flex justify-content-start align-items-center pt-3 mt-3 pt-lg-3 mt-lg-4'>
                                        <div className='activeTab' />
                                        <div className='ml-3'>
                                            <span className='activeTabText' style={{ display: imageIndex === 0 ? "none" : "block" }}>{translate(props.lang, "Adventurer Plan")}</span>
                                            <span className='activeTabText' style={{ display: imageIndex === 1 ? "none" : "block" }}>{translate(props.lang, "Explorer Plan")}</span>
                                        </div>
                                    </div>

                                    <div style={{ display: imageIndex === 0 ? "block" : "none" }}>
                                        <div className='pt-4'>
                                            <div className='row justify-content-start align-items-center text-left'>
                                                <div className='col-12 col-md-12 mb-4'>
                                                    <div className=''>
                                                        <div className='d-flex justify-content-start align-items-center' id="lockDropDown">
                                                            <span className='font-EN-Regular statsTitle'>{translate(props.lang, "Lock")}</span>
                                                            <UncontrolledDropdown setActiveFromChild style={{ textAlign: 'left', backgroundColor: "transparent" }}>
                                                                <DropdownToggle tag="a" className="nav-item" style={{}}>
                                                                    <FaAngleDown className='ml-2 cursor-pointer' fontSize={"18"} color='rgba(255, 255, 255, 0.6)' />
                                                                </DropdownToggle>
                                                                <DropdownMenu left style={{ height: "100%", backgroundColor: "transparent", marginTop: "0px" }}>
                                                                    <DropdownItem className="dropdownGame" style={{ background: "transparent", marginTop: "0px", color: "white" }} onClick={() => setMonth(0)}>{translate(props.lang, "1/2 Month")}</DropdownItem>
                                                                    <DropdownItem className="dropdownGame" style={{ background: "transparent", marginTop: "0px", color: "white" }} onClick={() => setMonth(1)}>{translate(props.lang, "1 Month")}</DropdownItem>
                                                                    <DropdownItem className="dropdownGame" style={{ background: "transparent", marginTop: "0px", color: "white" }} onClick={() => setMonth(3)}>{translate(props.lang, "3 Month")}</DropdownItem>
                                                                    <DropdownItem className="dropdownGame" style={{ background: "transparent", marginTop: "0px", color: "white" }} onClick={() => setMonth(6)}>{translate(props.lang, "6 Month")}</DropdownItem>
                                                                    <DropdownItem className="dropdownGame" style={{ background: "transparent", marginTop: "0px", color: "white" }} onClick={() => setMonth(9)}>{translate(props.lang, "9 Month")}</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                        <span className='font-EN-Bold AcountSubTitle text-white'>{selectedMonth === 0 ? "1/2" : selectedMonth} {translate(props.lang, "Month")}</span>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-3 col-lg-4 mb-4'>
                                                    <div className=''>
                                                        <span className='font-EN-Regular statsTitle'>{translate(props.lang, "Deposit")}</span> <br />
                                                        {/* <span className='font-EN-Bold AcountSubTitle text-white'>{imageIndex === 1 ? translate(props.lang, "$800 & Above") : "$300-799"}</span> */}
                                                        <span className='font-EN-Bold AcountSubTitle text-white'>{selectedMonth === 0 ? translate(props.lang, "90 and above") : selectedMonth === 1 ? translate(props.lang, "150 and above") : selectedMonth === 3 ? translate(props.lang, "300 and above") : selectedMonth === 6 ? translate(props.lang, "500 and above") : translate(props.lang, "Coming Soon")}</span>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-3 col-lg-4 mb-4'>
                                                    <div className=''>
                                                        <span className='font-EN-Regular statsTitle'>{translate(props.lang, "ROI")}</span> <br />
                                                        <div className='' style={{ display: imageIndex === 0 ? "block" : "none" }}>
                                                            <span className='font-EN-Bold AcountSubTitle text-white'>{selectedMonth === 0 ? "3%" : selectedMonth === 1 ? "6%" : selectedMonth === 3 ? "9%" : selectedMonth === 6 ? "11%" : translate(props.lang, "Coming Soon")}</span>
                                                        </div>
                                                        <div className='' style={{ display: imageIndex === 1 ? "block" : "none" }}>
                                                            <span className='font-EN-Bold AcountSubTitle text-white'>{selectedMonth === 0 ? "3%" : selectedMonth === 1 ? "6%" : selectedMonth === 3 ? "9%" : selectedMonth === 6 ? "11%" : translate(props.lang, "Coming Soon")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-3 col-lg-4 mb-4'>
                                                    <div className=''>
                                                        <span className='font-EN-Regular statsTitle'>{translate(props.lang, "Referral Bonus")}</span> <br />
                                                        <span className='font-EN-Bold AcountSubTitle text-white'>{selectedMonth === 0 ? "-" : selectedMonth === 1 ? translate(props.lang, "5 USD") : selectedMonth === 3 ? translate(props.lang, "15 USD") : selectedMonth === 6 ? translate(props.lang, "15 USD") : translate(props.lang, "Coming Soon")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row justify-content-start align-items-center text-left'>
                                                <div className='col-12 col-md-3 col-lg-4 mb-4'>
                                                    <div className=''>
                                                        <span className='font-EN-Regular statsTitle'>{translate(props.lang, "Customer Profit")}</span> <br />
                                                        <span className='font-EN-Bold AcountSubTitle text-white'>{imageIndex === 1 ? "80%" : "80%"}</span>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-3 col-lg-4 mb-4'>
                                                    <div className=''>
                                                        <span className='font-EN-Regular statsTitle'>{translate(props.lang, "Profit Sharing")}</span> <br />
                                                        <span className='font-EN-Bold AcountSubTitle text-white'>20%</span>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-3 col-lg-4 mb-4'>
                                                    <div className=''>
                                                        <span className='font-EN-Regular statsTitle'>{translate(props.lang, "Management Fees")}</span> <br />
                                                        <span className='font-EN-Bold AcountSubTitle text-white'>{selectedMonth === 0 ? "1.0%" : selectedMonth === 1 ? "2.0%" : selectedMonth === 3 ? "1.8%" : selectedMonth === 6 ? "1.5%" : translate(props.lang, "Coming Soon")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=''>
                                            <span className='font-EN-Regular statsTitle'>{translate(props.lang, "Withdrawal Period")}</span> <br />
                                            <span className='font-EN-Bold AcountSubTitle text-white'>{translate(props.lang, "Within 3 Working Days")}</span>
                                        </div>
                                    </div>

                                    <div className='py-5' style={{ display: imageIndex === 1 ? "block" : "none" }}>
                                        <div className='position-relative'>
                                            <img src={require('../../assets/img/trading/accountType/comingSoonBg.png').default} className="img-fluid mx-auto" />
                                            <div className='comingSoonPos'>
                                                <span className='font-EN-Bold comingSoonTitle text-white'>{translate(props.lang, "Coming Soon")}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='pt-4'>
                                        <span className='font-EN-Regular AcountSubTitle' style={{ color: "rgba(255, 255, 255, 0.6)" }}>
                                            {imageIndex === 0 ?
                                                translate(props.lang, "This account is perfect for beginner traders who are just starting out. With low minimum deposits and competitive spreads, this account type provides an accessible and affordable way to enter the world of forex trading.")
                                                :
                                                translate(props.lang, "Adventurer Plan is perfect for experienced traders who demand the best and provides everything you need to stay ahead of the curve. With our team of expert traders on hand, you can be sure you're getting the most out of your trading experience.")
                                            }
                                        </span>
                                    </div>

                                    {/* <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                        <span className=''>{translate(props.lang, "Open Live Account")}</span>
                                    </div> */}
                                </div>
                            </div>

                            <div className='col-11 col-md-9 col-lg-6 text-center order-1 order-lg-2 mb-5'>
                                <div className=''>
                                    <Slider {...settings} className="text-center" style={{ zIndex: "10", position: "sticky" }}>
                                        <div className={imageIndex === 0 ? "" : ""} >
                                            <div className='row justify-content-center align-items-start'>
                                                <div className='col-12 col-md-10 col-lg-10'>
                                                    {/* <img src={require('../../assets/img/trading/accountType/robotImg.png').default} className="img-fluid mx-auto " /> */}
                                                    {/* <img src={require('../../assets/img/trading/accountType/robot1.png').default} className="img-fluid mx-auto d-none d-lg-block" /> */}
                                                    <div className='position-relative' style={{}}>
                                                        <img src={require('../../assets/img/home/robotImgTest.png').default} className="img-fluid robotSize mb-5" />
                                                        <Lottie
                                                            loop
                                                            animationData={lottieJson}
                                                            play
                                                            className='podiumBottomPos'
                                                            style={{ width: "100%", height: "auto", position: "absolute", left: "0%" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={imageIndex === 1 ? "" : ""} >
                                            <div className='row justify-content-center align-items-start'>
                                                <div className='col-12 col-md-10 col-lg-10'>
                                                    {/* <img src={require('../../assets/img/trading/accountType/robot3Img.png').default} className="img-fluid mx-auto robotSize" /> */}
                                                    {/* <img src={require('../../assets/img/trading/accountType/robot2.png').default} className="img-fluid mx-auto d-none d-lg-block" /> */}
                                                    <div className='position-relative' style={{}}>
                                                        <img src={require('../../assets/img/home/robot3ImgTest.png').default} className="img-fluid robotSize mb-5" />
                                                        <Lottie
                                                            loop
                                                            animationData={lottieJson}
                                                            play
                                                            className='podiumBottomPos'
                                                            style={{ width: "100%", height: "auto", position: "absolute", left: "0%" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='planBg py-5'>
                    <div className='py-5 w-100'>
                        <div className='row justify-content-center align-items-center mx-0'>
                           
                        </div>
                    </div>
                </div> */}

                <div className='tradingStepBg d-flex align-items-center justify-content-center py-5'>
                    <ParticlesBubble />
                    <div className='row justify-content-center align-items-center mx-0 py-5 w-100'>
                        <div className='col-12 col-md-10 col-lg-11 text-center'>
                            <span className='font-EN-Bold sectionTitle text-purple'>{translate(props.lang, "Start Trading")}</span>
                            <div className='pt-2'>
                                <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Start trading with Vanguard Capital Group in 4 Steps")}</span>
                            </div>
                        </div>

                        <div className='d-none d-md-block'>
                            <div className='d-flex justify-content-center align-items-center pt-5 mt-5 text-center'>
                                <div>
                                    <img src={require('../../assets/img/trading/accountType/trade1.png').default} className="img-fluid mx-auto tradeImg" />
                                    <p className='font-EN-Bold headerSubTitle text-blue mt-4'>{translate(props.lang, "Register")}</p>
                                </div>
                                <img src={require('../../assets/img/trading/accountType/arrow1.png').default} className="img-fluid mx-auto tradeImg" />
                                <div>
                                    <img src={require('../../assets/img/trading/accountType/trade2.png').default} className="img-fluid mx-auto tradeImg" />
                                    <p className='font-EN-Bold headerSubTitle text-blue mt-4'>{translate(props.lang, "Verify")}</p>
                                </div>
                                <img src={require('../../assets/img/trading/accountType/arrow2.png').default} className="img-fluid mx-auto tradeImg" />
                                <div>
                                    <img src={require('../../assets/img/trading/accountType/trade3.png').default} className="img-fluid mx-auto tradeImg" />
                                    <p className='font-EN-Bold headerSubTitle text-blue mt-4'>{translate(props.lang, "Deposit")}</p>
                                </div>
                                <img src={require('../../assets/img/trading/accountType/arrow3.png').default} className="img-fluid mx-auto tradeImg" />
                                <div>
                                    <img src={require('../../assets/img/trading/accountType/trade4.png').default} className="img-fluid mx-auto tradeImg" />
                                    <p className='font-EN-Bold headerSubTitle text-blue mt-4'>{translate(props.lang, "Trade")}</p>
                                </div>
                            </div>
                        </div>

                        <div className='d-block d-md-none'>
                            <div className='d-block justify-content-center align-items-center pt-5 mt-5'>
                                <div>
                                    <img src={require('../../assets/img/trading/accountType/trade1.png').default} className="img-fluid mx-auto tradeImg" />
                                    <p className='font-EN-Bold headerSubTitle text-blue mt-4'>{translate(props.lang, "Register")}</p>
                                </div>
                                <img src={require('../../assets/img/trading/accountType/arrow1.png').default} className="img-fluid mx-auto tradeImg arrowMobile" />
                                <div>
                                    <img src={require('../../assets/img/trading/accountType/trade2.png').default} className="img-fluid mx-auto tradeImg" />
                                    <p className='font-EN-Bold headerSubTitle text-blue mt-4'>{translate(props.lang, "Verify")}</p>
                                </div>
                                <img src={require('../../assets/img/trading/accountType/arrow2.png').default} className="img-fluid mx-auto tradeImg arrowMobile" />
                                <div>
                                    <img src={require('../../assets/img/trading/accountType/trade3.png').default} className="img-fluid mx-auto tradeImg" />
                                    <p className='font-EN-Bold headerSubTitle text-blue mt-4'>{translate(props.lang, "Deposit")}</p>
                                </div>
                                <img src={require('../../assets/img/trading/accountType/arrow3.png').default} className="img-fluid mx-auto tradeImg arrowMobile" />
                                <div>
                                    <img src={require('../../assets/img/trading/accountType/trade4.png').default} className="img-fluid mx-auto tradeImg" />
                                    <p className='font-EN-Bold headerSubTitle text-blue mt-4'>{translate(props.lang, "Trade")}</p>
                                </div>
                            </div>
                        </div>

                        <div className='row justify-content-center align-items-center mx-0 py-5 w-100'>
                            <div className='col-12 col-md-10 col-lg-11 text-center'>
                                <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                    <div className='learnMoreBtn mt-5 py-3 px-5 mx-auto cursor-pointer'>
                                        <span className=''>{translate(props.lang, "Open Live Account")}</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(AccountType);
