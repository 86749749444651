import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ParticlesBubble from 'views/Particles';

function AboutUs(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className='company'>
                <div className='headerSection' id="advantages">
                    <div className='row justify-content-center justify-content-lg-end w-100 mx-0'>
                        <div className='col-12 col-md-10 col-lg-5 text-center text-lg-left pr-2 pr-lg-5'>
                            <div className='pr-2 pr-lg-5'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "MANAGED PORTFOLIOS")}</span>
                                <div className='pt-3'>
                                    <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Let The expert trade for you")}</span><br />
                                </div>
                                <div className='pt-3'>
                                    <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Invest in a portfolio managed by experts to target the highest long-term returns for you risk appetite")}</span>
                                </div>
                                <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                    <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                        <span className=''>{translate(props.lang, "MANAGED PORTFOLIOS")}</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='aboutUsSection headerSection w-100 text-right position-relative'>
                    <ParticlesBubble />
                    <img src={require('../../assets/img/company/aboutUs/aboutUsImg.png').default} className="img-fluid aboutUsImg" />
                    <div className='aboutUsContainerPos py-4'>
                        <div className='row justify-content-center w-100 text-center py-5 mx-0'>
                            <div className='col-12 col-md-11'>
                                <span className='font-EN-Bold sectionAboutUsTitle text-purple'>{translate(props.lang, "Why vanguard Capital Group")}</span>
                                <div className='pt-4'>
                                    <span className='font-EN-Regular sectionAboutUsDesc text-purple'>{translate(props.lang, "Vanguard Capital Group, was established in 2005 and we are a professional and advanced forex broker. We have a total of 500,000+ users. Our mission as a broker is to provide our clients with a secure and reliable trading environment, while also offering exceptional customer service and competitive pricing.")}</span> <br /><br />
                                    <span className='font-EN-Regular sectionAboutUsDesc text-purple'>{translate(props.lang, "As we continue to grow and expand our services, our future development goals include a focus on continuous innovation and the development of new technologies to improve our clients' trading experience. We are committed to staying ahead of the curve and ensuring that our clients have access to the latest tools and resources in the industry. At Vanguard Capital Group, we are dedicated to providing our clients with the best possible trading experience and helping them achieve their financial goals.")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='visMisBg headerSection w-100 text-right position-relative'>
                    <div className='row justify-content-center w-100 text-center py-5 mx-0'>

                        <div className='col-12 col-lg-5 text-center text-lg-left mb-5'>
                            <span className='font-EN-Bold sectionAboutUsTitle text-white'>{translate(props.lang, "Our Vision & Mission")}</span>
                        </div>
                        <div className='col-11 col-md-9 col-lg-5 text-left'>
                            <div className='d-block d-md-flex justify-content-start align-items-start text-center text-md-left mb-5'>
                                <img src={require('../../assets/img/company/aboutUs/vision-icon.png').default} className="img-fluid visMisImgSize pt-2" />
                                <div className='ml-0 ml-md-5 pt-4 pt-md-0'>
                                    <span className='font-EN-Medium visMisTitle text-white'>{translate(props.lang, "Vision")}</span>
                                    <div className='pt-4 text-left'>
                                        <ul className='text-white'>
                                            <li className='mb-3'>
                                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Become an internationally leading intelligent investment management company")}</span>
                                            </li>
                                            <li className='mb-3'>
                                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Provide efficient, safe, and intelligent asset management services to global investors.")}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='d-block d-md-flex justify-content-start align-items-start text-center text-md-left'>
                                <img src={require('../../assets/img/company/aboutUs/mision-icon.png').default} className="img-fluid visMisImgSize pt-2" />
                                <div className='ml-0 ml-md-5 pt-4 pt-md-0'>
                                    <span className='font-EN-Medium visMisTitle text-white'>{translate(props.lang, "Mission")}</span>
                                    <div className='pt-4 text-left'>
                                        <ul className='text-white'>
                                            <li className='mb-3'>
                                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Help investors achieve long-term and stable wealth growth")}</span>
                                            </li>
                                            <li className='mb-3'>
                                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Provide intelligent investment concepts and innovative technologies")}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(AboutUs);
