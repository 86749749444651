import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ParticlesBubble from '../Particles';

function PreciousMetal(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className='product'>
                <div className='headerSection' id="preciousMetal">
                    <div className='row justify-content-center justify-content-lg-start w-100 mx-0'>
                        <div className='col-12 col-lg-1' />
                        <div className='col-12 col-lg-6 text-center text-lg-left'>
                            <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Precious Metal Trading with ")}</span>
                            <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Vanguard Capital Group")}</span>
                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "We offers professional and transparent trading services for precious metal")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Open Live Account")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection vh-min-100 d-flex align-items-center justify-content-center bg-white'>
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-8 col-lg-6 text-center mb-5'>
                            <img src={require('../../assets/img/product/preciousMetal/detailsImg.png').default} className="img-fluid productDetailsImgSize mx-auto" />
                        </div>
                        <div className='col-12 col-md-10 col-lg-5 mb-4 text-center text-lg-left'>
                            <span className='font-EN-Bold sectionProductTitle text-blue'>{translate(props.lang, "Protect & Grow Your Wealth with ")}</span>
                            <span className='font-EN-Bold sectionProductTitle text-purple3'>{translate(props.lang, "Precious Metal Trading")}</span>

                            <div className='pt-4'>
                                <ul className='text-left'>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Precious metals provide protection against currency inflation.")}</span>
                                    </li>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Investors can benefit from price differences and maintain value during economic downturns.")}</span>
                                    </li>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Investors can make investment decisions based on their preferences.")}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection vh-min-100 d-flex align-items-center justify-content-center'>
                    <ParticlesBubble />
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-10 col-lg-9 text-center'>
                            <span className='font-EN-Bold sectionTitle text-purple-light2'>{translate(props.lang, "At Vanguard ")}</span>
                            <span className='font-EN-Bold sectionTitle text-purple3'>{translate(props.lang, "Capital Group")}</span>

                            <div className='pt-5'>
                                <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "We are committed to providing our clients with the advantages of precious metal trading and helping them achieve their financial goals. Our platform offers a safe and transparent environment for investors to trade precious metals, ensuring that they have access to the latest tools and resources in the industry.")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 mx-auto cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Start Trading Now")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(PreciousMetal);
