import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ParticlesBubble from '../Particles';

function AiPowered(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className='company'>
                <div className='headerSection' id="AIpower">
                    <div className='row justify-content-center justify-content-lg-start w-100 mx-0'>
                        <div className='col-12 col-lg-1' />
                        <div className='col-12 col-lg-5 text-center text-lg-left'>
                            <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "AI - Powered Forex Trading")}</span><br />
                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Revolutionizing the way you trade")}</span>
                            </div>
                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5'>
                                    <span className=''>{translate(props.lang, "Open Live Account")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection bg-white w-100'>
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-10 col-lg-9 mb-4 text-center pt-5'>
                            <span className='font-EN-Bold sectionTitle text-blue'>{translate(props.lang, "Advantages of ")}</span>
                            <span className='font-EN-Bold sectionTitle text-purple3'>{translate(props.lang, "AI - Powered Forex Automatic Trading ")}</span>
                        </div>

                        <div className='col-11 mb-4'>
                            <div className='text-center mapBg headerSection position-relative d-none d-lg-block'>
                                <div className='mapItemDiv' id="mapItemDiv1">
                                    <div className='mapItemContainer' id="mapItemContainer2">
                                        <div className='mapItemImgPos' id="mapItemImgPos1">
                                            <img src={require('../../assets/img/company/aiPowered/power1.png').default} className="img-fluid powerImgSize" />
                                        </div>
                                        <div className='mapItemContainerContent text-left px-4 py-4'>
                                            <span className='font-EN-Bold mapItemTitle text-white'>{translate(props.lang, "Accuracy")}</span> <br /><br />
                                            <span className='font-EN-Regular newsDesc text-white'>{translate(props.lang, "AI-powered forex automatic trading can analyze more market data and make more accurate decisions based on data, improving the success rate of trades.")}</span> <br />
                                        </div>
                                    </div>
                                </div>

                                <div className='mapItemDiv' id="mapItemDiv2">
                                    <div className='mapItemContainer' id="mapItemContainer1">
                                        <div className='mapItemImgPos' id="mapItemImgPos2">
                                            <img src={require('../../assets/img/company/aiPowered/power2.png').default} className="img-fluid powerImgSize" />
                                        </div>
                                        <div className='mapItemContainerContent text-left px-4 py-4'>
                                            <span className='font-EN-Bold mapItemTitle text-white'>{translate(props.lang, "Efficiency")}</span> <br /><br />
                                            <span className='font-EN-Regular newsDesc text-white'>{translate(props.lang, "AI-powered forex automatic trading can execute trades quickly, avoiding the impact of human factors, and can simultaneously monitor multiple markets to improve trading efficiency")}</span> <br />
                                        </div>
                                    </div>
                                </div>

                                <div className='mapItemDiv' id="mapItemDiv3">
                                    <div className='mapItemContainer' id="mapItemContainer1">
                                        <div className='mapItemImgPos' id="mapItemImgPos1">
                                            <img src={require('../../assets/img/company/aiPowered/power3.png').default} className="img-fluid powerImgSize" />
                                        </div>
                                        <div className='mapItemContainerContent text-left px-4 py-4'>
                                            <span className='font-EN-Bold mapItemTitle text-white'>{translate(props.lang, "Risk Management")}</span> <br /><br />
                                            <span className='font-EN-Regular newsDesc text-white'>{translate(props.lang, "AI-powered forex automatic trading can execute trades based on preset risk management rules, avoiding the risks associated with emotional trading and decision-making errors")}</span> <br />
                                        </div>
                                    </div>
                                </div>

                                <div className='mapItemDiv' id="mapItemDiv4">
                                    <div className='mapItemContainer' id="mapItemContainer2">
                                        <div className='mapItemImgPos' id="mapItemImgPos1">
                                            <img src={require('../../assets/img/company/aiPowered/power4.png').default} className="img-fluid powerImgSize" />
                                        </div>
                                        <div className='mapItemContainerContent text-left px-4 py-4'>
                                            <span className='font-EN-Bold mapItemTitle text-white'>{translate(props.lang, "Adaptability")}</span> <br /><br />
                                            <span className='font-EN-Regular newsDesc text-white'>{translate(props.lang, "AI-powered forex automatic trading can adjust its trading strategy to market changes, maintaining its adaptability and effectiveness.")}</span> <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-block d-lg-none'>
                        <div className='row justify-content-center align-items-center mx-0 py-5'>
                            <div className='col-12 col-md-6 mb-5'>
                                <div className='mapItemContainerMobile'>
                                    <div className='mapItemImgPos' id="mapItemImgPos1">
                                        <img src={require('../../assets/img/company/aiPowered/power1.png').default} className="img-fluid powerImgSize" />
                                    </div>
                                    <div className='mapItemContainerContent text-left px-4 py-4'>
                                        <span className='font-EN-Bold mapItemTitle text-white'>{translate(props.lang, "Accuracy")}</span> <br /><br />
                                        <span className='font-EN-Regular newsDesc text-white'>{translate(props.lang, "AI-powered forex automatic trading can analyze more market data and make more accurate decisions based on data, improving the success rate of trades.")}</span> <br />
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mb-5'>
                                <div className='mapItemContainerMobile'>
                                    <div className='mapItemImgPos' id="mapItemImgPos2">
                                        <img src={require('../../assets/img/company/aiPowered/power2.png').default} className="img-fluid powerImgSize" />
                                    </div>
                                    <div className='mapItemContainerContent text-left px-4 py-4'>
                                        <span className='font-EN-Bold mapItemTitle text-white'>{translate(props.lang, "Efficiency")}</span> <br /><br />
                                        <span className='font-EN-Regular newsDesc text-white'>{translate(props.lang, "AI-powered forex automatic trading can execute trades quickly, avoiding the impact of human factors, and can simultaneously monitor multiple markets to improve trading efficiency")}</span> <br />
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mb-5'>
                                <div className='mapItemContainerMobile'>
                                    <div className='mapItemImgPos' id="mapItemImgPos1">
                                        <img src={require('../../assets/img/company/aiPowered/power3.png').default} className="img-fluid powerImgSize" />
                                    </div>
                                    <div className='mapItemContainerContent text-left px-4 py-4'>
                                        <span className='font-EN-Bold mapItemTitle text-white'>{translate(props.lang, "Risk Management")}</span> <br /><br />
                                        <span className='font-EN-Regular newsDesc text-white'>{translate(props.lang, "AI-powered forex automatic trading can execute trades based on preset risk management rules, avoiding the risks associated with emotional trading and decision-making errors")}</span> <br />
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mb-5'>
                                <div className='mapItemContainerMobile'>
                                    <div className='mapItemImgPos' id="mapItemImgPos1">
                                        <img src={require('../../assets/img/company/aiPowered/power4.png').default} className="img-fluid powerImgSize" />
                                    </div>
                                    <div className='mapItemContainerContent text-left px-4 py-4'>
                                        <span className='font-EN-Bold mapItemTitle text-white'>{translate(props.lang, "Adaptability")}</span> <br /><br />
                                        <span className='font-EN-Regular newsDesc text-white'>{translate(props.lang, "AI-powered forex automatic trading can adjust its trading strategy to market changes, maintaining its adaptability and effectiveness.")}</span> <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection d-flex align-items-center justify-content-center'>
                    <ParticlesBubble />
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-10 col-lg-9 text-center'>
                            <span className='font-EN-Bold sectionTitle text-purple-light2'>{translate(props.lang, "AI-Powered Forex ")}</span>
                            <span className='font-EN-Bold sectionTitle text-purple3'>{translate(props.lang, "Automatic Trading")}</span>

                            <div className='pt-5'>
                                <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "AI-powered forex automatic trading is a revolutionary technological advancement that offers investors an efficient, accurate, and risk-controlled trading experience. If you have any questions or need assistance regarding AI-powered forex automatic trading, please contact our customer service team, and we will be happy to assist you.")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 mx-auto cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Start Trading Now")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(AiPowered);
