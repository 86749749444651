import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import moment from "moment";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { FaAngleLeft, FaAngleRight, FaFacebookF, FaInstagram, FaLinkedinIn, FaStar, FaTelegramPlane } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ParticlesBubble from 'views/Particles';
// import userProfile from "../../assets/img/tools/marketNews/commentUser.png"
// import news1 from "../../assets/img/tools/marketNews/news1.png"
// import news2 from "../../assets/img/tools/marketNews/news2.png"
// import news3 from "../../assets/img/tools/marketNews/news3.png"

// const newsArray2 = [
//     { img: news1, title: "USD/CHF - Swiss franc climb higher", desc: "Traders and investors use trading news to make informed decisions about buying and selling securities, currencies, commodities, and other financial instruments.", userProfile: userProfile, username: "Courtney Henry", timespan: "10 hours" },
//     { img: news2, title: "USD/CHF - Swiss franc climb higher", desc: "Traders and investors use trading news to make informed decisions about buying and selling securities, currencies, commodities, and other financial instruments.", userProfile: userProfile, username: "Courtney Henry", timespan: "10 hours" },
//     { img: news3, title: "USD/CHF - Swiss franc climb higher", desc: "Traders and investors use trading news to make informed decisions about buying and selling securities, currencies, commodities, and other financial instruments.", userProfile: userProfile, username: "Courtney Henry", timespan: "10 hours" },
//     { img: news1, title: "USD/CHF - Swiss franc climb higher", desc: "Traders and investors use trading news to make informed decisions about buying and selling securities, currencies, commodities, and other financial instruments.", userProfile: userProfile, username: "Courtney Henry", timespan: "10 hours" },
//     { img: news2, title: "USD/CHF - Swiss franc climb higher", desc: "Traders and investors use trading news to make informed decisions about buying and selling securities, currencies, commodities, and other financial instruments.", userProfile: userProfile, username: "Courtney Henry", timespan: "10 hours" },
//     { img: news3, title: "USD/CHF - Swiss franc climb higher", desc: "Traders and investors use trading news to make informed decisions about buying and selling securities, currencies, commodities, and other financial instruments.", userProfile: userProfile, username: "Courtney Henry", timespan: "10 hours" },
// ]

function MarketNews(props) {
    const [newsArray, setNewsArray] = React.useState([]);

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow newsNext cursor-pointer" id="nextArrow" onClick={onClick} style={{ backgroundColor: "transparent" }}>
                <FaAngleRight fontSize={30} color='#20378C' />
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow newsPrev cursor-pointer" id="prevArrow" onClick={onClick} style={{ backgroundColor: "transparent" }}>
                <FaAngleLeft fontSize={30} color='#20378C' />
            </div>
        );
    };

    const [imageIndex, setImageIndex] = React.useState(0);

    const settings = {
        dots: false,
        infinite: true,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: true,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 3 : window.innerWidth > 677 ? 2 : 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        swipe: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    React.useEffect(() => {
        getAllNewsPost();
    }, []);

    async function getAllNewsPost() {
        const resp = await axios.get("https://forexnewsapi.com/api/v1/category?section=general&items=100&token=fka7lacfeykb2bc1mah64oqzjbdqtywvme4ek0ns");
        console.log("RESP", resp);
        setNewsArray(resp.data.data);
    }

    TimeAgo.addDefaultLocale(en);
    const timeAgo = new TimeAgo('en-US');

    function ConvertToMilliSecond(publishDateTime) {
        var myDate = new Date(publishDateTime);
        var result = moment(myDate).valueOf();
        console.log("RESULT", result);
        var dateNow = moment().valueOf();
        var dateDiff = dateNow - result;
        return dateDiff;
    }

    return (
        <>
            <IndexNavbar />
            <div className='tools'>
                <ParticlesBubble />
                <div className='py-5'>
                    <div className='position-relative py-5'>
                        <div className='row justify-content-center align-items-center w-100 mx-0 pt-5'>
                            <div className='col-12 col-md-11 col-lg-5 text-center text-md-left mb-4'>
                                <span className='font-EN-Bold headerTitle text-purple'>{translate(props.lang, "Market News")}</span>
                            </div>
                            <div className='col-12 col-md-11 col-lg-6 text-center'>
                                {/* <div className='allNewsBtn py-3 px-5 cursor-pointer'>
                                    <span className=''>{translate(props.lang, "View All News")}</span>
                                </div> */}
                            </div>
                        </div>


                        <div className='row justify-content-center align-items-center mx-auto pb-5'>
                            <div className='col-12 col-md-12 col-lg-11'>
                                <Slider {...settings} className="py-5 text-center" style={{ zIndex: "10", position: "sticky" }}>
                                    {
                                        newsArray.map((item, i) => (
                                            <div className={imageIndex === i ? "newsDiv" : "newsDiv"} >
                                                <a href={item.news_url} target='_blank' style={{ textDecoration: "none" }}>
                                                    <div className='newsContainer'>
                                                        <div className='newsImgContainer'>
                                                            <img src={item.image_url} className='w-100' />
                                                        </div>
                                                        <div className='row justify-content-start align-items-start px-2 px-lg-3 pt-4 text-left newsContentHeight'>
                                                            <div className='col-12'>
                                                                <span className='font-EN-Bold newsTitle'>{translate(props.lang, item.title)}</span> <br /><br />
                                                                <span className='font-EN-Regular newsDesc'>{translate(props.lang, item.text)}</span> <br />
                                                            </div>
                                                        </div>

                                                        <div className='row justify-content-start align-items-start px-0 px-lg-3 pb-4 text-left'>
                                                            <div className='col-12'>
                                                                <div className='pt-5'>
                                                                    <div className='row justify-content-start align-items-center mx-0'>
                                                                        <div className='col-6 text-left'>
                                                                                <span className='font-EN-Regular newsAuthor'>{translate(props.lang, item.source_name)}</span>
                                                                        </div>

                                                                        <div className='col-6 text-right'>
                                                                            <span className='font-EN-Regular newsAuthor'>{timeAgo.format(Date.now() - ConvertToMilliSecond(item.date))}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))
                                    }

                                    {/* {
                                        newsArray2.map((item, i) => (
                                            <div className={imageIndex === i ? "newsDiv" : "newsDiv"} >
                                                <a href={item.url} target='_blank' style={{ textDecoration: "none" }}>
                                                    <div className='newsContainer'>
                                                        <div className='newsImgContainer'>
                                                            <img src={item.img} className='w-100' />
                                                        </div>
                                                        <div className='row justify-content-start align-items-start px-2 px-lg-3 pt-4 text-left newsContentHeight'>
                                                            <div className='col-12'>
                                                                <span className='font-EN-Bold newsTitle'>{translate(props.lang, item.title)}</span> <br /><br />
                                                                <span className='font-EN-Regular newsDesc'>{translate(props.lang, item.desc)}</span> <br />
                                                            </div>
                                                        </div>

                                                        <div className='row justify-content-start align-items-start px-2 px-lg-3 pb-4 text-left'>
                                                            <div className='col-12'>
                                                                <div className='pt-5'>
                                                                    <div className='row justify-content-start align-items-center mx-0'>
                                                                        <div className='col-8'>
                                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                                <img src={item.userProfile} className='mr-3 newsUserProfile' />
                                                                                <span className='font-EN-Regular newsAuthor'>{translate(props.lang, item.username)}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-4 text-right'>
                                                                            <span className='font-EN-Regular newsAuthor'>{item.timespan}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))
                                    } */}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(MarketNews);
