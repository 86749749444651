import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import "./assets/css/custom.scss";
import "./assets/css/home.scss";
import "./assets/css/product.scss";
import "./assets/css/support.scss";
import "./assets/css/tools.scss";
import "./assets/css/company.scss";
import "./assets/css/company.scss";
import "./assets/css/trading.scss";

import ForexExchange from "views/product/ForexExchange";
import ContactUs from "views/support/ContactUs";
import FAQ from "views/support/FAQ";
import CTrader from "views/tools/CTrader";
import PreciousMetal from "views/product/PreciousMetal";
import CrudeOil from "views/product/CrudeOil";
import IndexProduct from "views/product/IndexProduct";
import ContractDifferent from "views/product/ContractDifferent";
import MarketNews from "views/tools/MarketNews";
import AiPowered from "views/company/AiPowered";
import Advantages from "views/company/Advantages";
import AboutUs from "views/company/AboutUs";
import VCG_Pool from "views/trading/VCG_Pool";
import AccountType from "views/trading/AccountType";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Switch>
          <Route path="/index" render={(props) => <Index {...props} />} />

          <Route path="/company/about-us" render={(props) => <AboutUs {...props} />} />
          <Route path="/company/advantages" render={(props) => <Advantages {...props} />} />
          <Route path="/company/ai-powered" render={(props) => <AiPowered {...props} />} />

          <Route path="/product/forex-exchange" render={(props) => <ForexExchange {...props} />} />
          <Route path="/product/precious-metal" render={(props) => <PreciousMetal {...props} />} />
          <Route path="/product/crude-oil" render={(props) => <CrudeOil {...props} />} />
          <Route path="/product/index" render={(props) => <IndexProduct {...props} />} />
          <Route path="/product/contract-for-different" render={(props) => <ContractDifferent {...props} />} />

          <Route path="/trading/vcg-pool" render={(props) => <VCG_Pool {...props} />} />
          <Route path="/trading/account-type" render={(props) => <AccountType {...props} />} />

          <Route path="/tools/ctrader" render={(props) => <CTrader {...props} />} />
          <Route path="/tools/market-news" render={(props) => <MarketNews {...props} />} />

          <Route path="/support/contact-us" render={(props) => <ContactUs {...props} />} />
          <Route path="/support/faq" render={(props) => <FAQ {...props} />} />

          <Redirect to="/index" />
          <Redirect from="/" to="/index" />
        </Switch>
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
