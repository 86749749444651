import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { translate } from 'utils/translate';

import { FaAngleDown, FaGlobe, FaSortDown } from "react-icons/fa";
import { HashLink } from 'react-router-hash-link';

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

const lang = [
  { label: "English", value: "EN" },
  { label: "简体中文", value: "ZH_CN" },
];

function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-darkBlue");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [language, setLanguage] = useState(props.lang);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("navbar-darkBlue");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-darkBlue");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const selectLang = (item) => {
    setLanguage(item.value)
    dispatch({
      type: 'UPDATE_LANGUAGE',
      lang: item.value
    });
    localStorage.setItem('vcg-lang', item.value);
  }

  const selectLanguageMobile = (item) => {
    setLanguage(item);
    dispatch({
      type: 'UPDATE_LANGUAGE',
      lang: item
    });
    localStorage.setItem('vcg-lang', item);
  }

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <Container style={{ maxWidth: "100%" }}>
          <div className="navbar-translate">
            <NavbarBrand href="/index" id="navbar-brand">
              <img className="navBarLogo" src={require("../../assets/img/logo.png").default} />
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-start align-items-center ml-5"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar className="w-100">

              <NavItem className="menuLink p-0">
                <NavLink
                  data-placement="bottom"
                  rel="noopener noreferrer"
                  title="FAQs"
                  style={{ cursor: "pointer" }}
                >
                  <UncontrolledDropdown setActiveFromChild style={{ textAlign: 'left', backgroundColor: collapseOpen ? "transparent" : "transparent" }}>
                    <DropdownToggle tag="a" className="nav-item" style={{}} caret>
                      <span className="navItemText font-EN-Regular">{translate(props.lang, "Company")}</span>
                      <FaSortDown className="mb-2 ml-1 navbar-icon2" fontSize={18} />
                    </DropdownToggle>
                    <DropdownMenu left style={{ height: "100%", backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px" }}>
                      <a href="/company/about-us"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "About Us")}</DropdownItem></a>
                      <a href="/company/advantages"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "Advantages")}</DropdownItem></a>
                      <a href="/company/ai-powered"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "AI Powered")}</DropdownItem></a>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem>


              <NavItem className="menuLink p-0">
                <NavLink
                  data-placement="bottom"
                  rel="noopener noreferrer"
                  title="FAQs"
                  style={{ cursor: "pointer" }}
                >
                  <UncontrolledDropdown setActiveFromChild style={{ textAlign: 'left', backgroundColor: collapseOpen ? "transparent" : "transparent" }}>
                    <DropdownToggle tag="a" className="nav-item" style={{}} caret>
                      <span className="navItemText font-EN-Regular">{translate(props.lang, "Products")}</span>
                      <FaSortDown className="mb-2 ml-1 navbar-icon2" fontSize={18} />
                    </DropdownToggle>
                    <DropdownMenu left style={{ height: "100%", backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px" }}>
                      <a href="/product/forex-exchange"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "Forex Exchange")}</DropdownItem></a>
                      <a href="/product/precious-metal"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "Precious Metal")}</DropdownItem></a>
                      <a href="/product/crude-oil"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "Crude Oil")}</DropdownItem></a>
                      <a href="/product/index"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "Index")}</DropdownItem></a>
                      <a href="/product/contract-for-different"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "Contract For Difference")}</DropdownItem></a>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem>

              <NavItem className="menuLink p-0">
                <NavLink
                  data-placement="bottom"
                  rel="noopener noreferrer"
                  title="FAQs"
                  style={{ cursor: "pointer" }}
                >
                  <UncontrolledDropdown setActiveFromChild style={{ textAlign: 'left', backgroundColor: collapseOpen ? "transparent" : "transparent" }}>
                    <DropdownToggle tag="a" className="nav-item" style={{}} caret>
                      <span className="navItemText font-EN-Regular">{translate(props.lang, "Trading")}</span>
                      <FaSortDown className="mb-2 ml-1 navbar-icon2" fontSize={18} />
                    </DropdownToggle>
                    <DropdownMenu left style={{ height: "100%", backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px" }}>
                      <a href="/trading/vcg-pool"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "VCG World Pool")}</DropdownItem></a>
                      <a href="/trading/account-type"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "Account Type")}</DropdownItem></a>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem>

              <NavItem className="menuLink p-0">
                <NavLink
                  data-placement="bottom"
                  rel="noopener noreferrer"
                  title="FAQs"
                  style={{ cursor: "pointer" }}
                >
                  <UncontrolledDropdown setActiveFromChild style={{ textAlign: 'left', backgroundColor: collapseOpen ? "transparent" : "transparent" }}>
                    <DropdownToggle tag="a" className="nav-item" style={{}} caret>
                      <span className="navItemText font-EN-Regular">{translate(props.lang, "Tools")}</span>
                      <FaSortDown className="mb-2 ml-1 navbar-icon2" fontSize={18} />
                    </DropdownToggle>
                    <DropdownMenu left style={{ height: "100%", backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px" }}>
                      <a href="/tools/market-news"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "Market News")}</DropdownItem></a>
                      <a href="/tools/ctrader"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "CTrader")}</DropdownItem></a>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem>

              <NavItem className="menuLink p-0">
                <NavLink
                  data-placement="bottom"
                  rel="noopener noreferrer"
                  title="FAQs"
                  style={{ cursor: "pointer" }}
                >
                  <UncontrolledDropdown setActiveFromChild style={{ textAlign: 'left', backgroundColor: collapseOpen ? "transparent" : "transparent" }}>
                    <DropdownToggle tag="a" className="nav-item" style={{}} caret>
                      <span className="navItemText font-EN-Regular">{translate(props.lang, "Support")}</span>
                      <FaSortDown className="mb-2 ml-1 navbar-icon2" fontSize={18} />
                    </DropdownToggle>
                    <DropdownMenu left style={{ height: "100%", backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px" }}>
                      <a href="/support/contact-us"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "Contact Us")}</DropdownItem></a>
                      <a href="/support/faq"><DropdownItem className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>{translate(props.lang, "FAQS")}</DropdownItem></a>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem>

              <NavItem className="d-block d-lg-none py-4">
                <hr style={{ color: "white", background: "white", width: "90%" }} />
              </NavItem>

              <NavItem className="d-block d-lg-none">
                <NavLink href="https://client.vcgtraders.com/login" target="_blank">
                  <span className="navItemText font-EN-Regular">{translate(props.lang, "Login")}</span>
                </NavLink>
              </NavItem>

              <NavItem className="d-block d-lg-none">
                <NavLink href="https://client.vcgtraders.com/register" target="_blank">
                  <div className="signUpBtn py-2 px-4">
                    <span className="font-EN-Regular">{translate(props.lang, "Sign Up")}</span>
                  </div>
                </NavLink>
              </NavItem>

              <NavItem className="menuLink p-0 d-block d-lg-none">
                <NavLink
                  data-placement="bottom"
                  rel="noopener noreferrer"
                  title="FAQs"
                  style={{ cursor: "pointer" }}
                >
                  <UncontrolledDropdown setActiveFromChild style={{ textAlign: 'left', backgroundColor: collapseOpen ? "transparent" : "transparent" }}>
                    <DropdownToggle tag="a" className="nav-item" style={{}} caret>
                      <FaGlobe fontSize={18} className="navbar-icon" />
                      <FaSortDown className="mb-2 ml-1 navbar-icon2" fontSize={18} />
                    </DropdownToggle>
                    <DropdownMenu right style={{ height: "100%", backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px" }}>
                      <DropdownItem onClick={() => selectLanguageMobile('EN')} className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>English</DropdownItem>
                      <DropdownItem onClick={() => selectLanguageMobile('ZH_CN')} className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>简体中文</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem>

              <div className="d-none d-lg-block w-100">
                <div className="d-flex justify-content-end w-100" style={{ float: "right", width: "100%" }}>
                  <NavItem className="">
                    <NavLink href="https://client.vcgtraders.com/login" target="_blank">
                      <span className="navItemText font-EN-Regular">{translate(props.lang, "Login")}</span>
                    </NavLink>
                  </NavItem>

                  <NavItem className="">
                    <NavLink href="https://client.vcgtraders.com/register" target="_blank">
                      <div className="signUpBtn py-2 px-4">
                        <span className="font-EN-Regular">{translate(props.lang, "Sign Up")}</span>
                      </div>
                    </NavLink>
                  </NavItem>

                  <NavItem className="menuLink p-0">
                    <NavLink
                      data-placement="bottom"
                      rel="noopener noreferrer"
                      title="FAQs"
                      style={{ cursor: "pointer" }}
                    >
                      <UncontrolledDropdown setActiveFromChild style={{ textAlign: 'left', backgroundColor: collapseOpen ? "transparent" : "transparent" }}>
                        <DropdownToggle tag="a" className="nav-item" style={{}} caret>
                          <FaGlobe fontSize={18} className="navbar-icon" />
                          <FaSortDown className="mb-2 ml-1 navbar-icon2" fontSize={18} />
                        </DropdownToggle>
                        <DropdownMenu right style={{ height: "100%", backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px" }}>
                          <DropdownItem onClick={() => selectLanguageMobile('EN')} className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>English</DropdownItem>
                          <DropdownItem onClick={() => selectLanguageMobile('ZH_CN')} className="dropdownGame" style={{ backgroundColor: collapseOpen ? "transparent" : "black", marginTop: "0px", color: "white" }}>简体中文</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </NavLink>
                  </NavItem>
                </div>
              </div>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(IndexNavbar);

