import Particles from "react-tsparticles";
// import particlesConfig from "../assets/js/particles.json";
import { loadFull } from "tsparticles";
import "../assets/css/particle.css";
import { connect, useDispatch } from 'react-redux';

function ParticlesBubble(props) {
    const particlesInit = async (main) => {
        console.log(main);

        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const darkOptions =
    {
        "background": {
            "color": {
                "value": "white"
            },
            "position": "50% 50%",
            "repeat": "no-repeat",
            "size": "cover"
        },
        "fullScreen": {
            "zIndex": 1
        },
        "interactivity": {
            "detectsOn": "window",
            "events": {
                "onClick": {
                    "enable": true,
                    "mode": "push"
                },
                "onHover": {
                    "enable": true,
                    "mode": "repulse",
                    "parallax": {
                        "enable": false,
                        "force": 2,
                        "smooth": 10
                    }
                },
                "resize": {
                    "delay": 0.5,
                    "enable": true
                }
            },
        },
        "particles": {
            "color": {
                "value": "rgba(0,56,255,1)"
            },
            "links": {
                "color": {
                    "value": "rgba(0,56,255,0.5)"
                },
                "distance": 100,
                "enable": true,
                "opacity": 0.5
            },
            "move": {
                "attract": {
                    "rotate": {
                        "x": 600,
                        "y": 1200
                    }
                },
                "enable": true,
                "outModes": {
                    "bottom": "out",
                    "left": "out",
                    "right": "out",
                    "top": "out"
                }
            },
            "number": {
                "density": {
                    "enable": true
                },
                "value": 120
            },
            "opacity": {
                "random": {
                    "enable": true
                },
                "value": {
                    "min": 0.1,
                    "max": 0.5
                },
                "animation": {
                    "enable": true,
                    "speed": 3,
                    "minimumValue": 0.1
                }
            },
            "size": {
                "random": {
                    "enable": true
                },
                "value": {
                    "min": 0.1,
                    "max": 5
                },
                "animation": {
                    "enable": true,
                    "speed": 20,
                    "minimumValue": 0.1
                }
            },
            "twinkle": {
                "lines": {
                    "enable": true,
                    "frequency": 0.01,
                    "opacity": 1,
                    "color": {
                        "value": "rgba(125,191,245,0.5)"
                    }
                },
                "particles": {
                    "enable": true,
                    "frequency": 0.05,
                    "opacity": 1,
                    "color": {
                        "value": "#29ABE2"
                    }
                }
            }
        }
    }

    return (
        <div>
            <Particles id="tsparticles" canvasClassName="tsparticles" className="particle" height="100vh" width="100vw" options={props.mode === 'light' ? darkOptions : darkOptions} init={particlesInit} loaded={particlesLoaded} />
        </div>
    )
}

// const mapStateToProps = state => {
//     const { auth, i18n } = state;
//     return {
//       mode: auth.mode,
//     }
//   }
export default ParticlesBubble;