import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import { Input } from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function CTrader(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className='tools'>
                <div className='headerSection'>
                    <div className='row justify-content-center justify-content-lg-start align-items-center w-100 mx-0 pt-5 mt-5 pt-md-0 mt-md-0'>
                        <div className='col-12 col-lg-1' />
                        <div className='col-12 col-md-10 col-lg-4 text-center text-lg-left mb-5 order-2 order-lg-1'>
                            <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "CTrader")}</span><br />
                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "cTrader is a complete trading platform solution for Forex and CFD brokers to offer their traders. The platform is packed with a full range of features to cater to each and every investment preference imaginable.")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Open Live Account")}</span>
                                </div>
                            </a>
                        </div>
                        {/* <div className='col-12 col-lg-1 mb-5' /> */}

                        <div className='col-12 col-md-8 col-lg-6 text-left order-1 order-lg-2 mb-4 text-center'>
                            <img src={require('../../assets/img/tools/ctrader/headerImg.png').default} className="img-fluid mx-auto" style={{ width: "85%" }} />
                        </div>
                    </div>
                </div>

                <div className='bg-white py-5'>
                    <div className='row justify-content-center align-items-start w-100 mx-0 py-5'>
                        <div className='col-12 col-md-11 col-lg-8 text-center'>
                            <span className='font-EN-Bold headerTitle text-purple'>{translate(props.lang, "Trading with Vanguard Capital Group CTrader")}</span><br />
                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-purple'>{translate(props.lang, "A Premium Charting and Manual Trading PlatformcTrader is a leading multi-asset Forex and CFD trading platform, offering rich charting tools, advanced order types, level II pricing, and fast entry and execution. With a stunning user interface, it’s connected to the most sophisticated backend technology, and made available on multiple")}</span>
                            </div>
                        </div>
                    </div>

                    <div className='row justify-content-start align-items-start w-100 mx-0 py-5 ctraderWebBg'>
                        <div className='col-12 col-md-6 col-lg-6 text-center mb-3'>
                            <img src={require('../../assets/img/tools/ctrader/ctraderWeb.png').default} className="img-fluid" />
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 text-center text-md-left mb-3'>
                            <span className='font-EN-Bold headerTitle text-purple3'>{translate(props.lang, "CTrader Web")}</span><br />
                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-purple'>{translate(props.lang, "with CTrader Web, users can access all the features of cTrader from any popular browser without the need for installation. It's a fast and easy way to trade on desktops running Windows, Mac, or Linux operating systems.")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Explore Now")}</span>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className='row justify-content-center align-items-start w-100 mx-0 py-5 ctraderMobileBg'>
                        <div className='col-12 col-md-6 col-lg-4 text-center text-md-left order-2 order-md-1 mb-3'>
                            <span className='font-EN-Bold headerTitle text-purple3'>{translate(props.lang, "CTrader Mobile")}</span><br />
                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-purple'>{translate(props.lang, "Traders can download the native cTrader app from either the App Store or Google Play Store for their iOS or Android mobile devices. The tablet-optimized apps offer a variety of key features for effective on-the-go trading and analysis from anywhere in the world.")}</span>
                            </div>
                            <div className='pt-4 d-flex justify-content-center justify-content-md-start align-items-start'>
                                <img src={require('../../assets/img/tools/ctrader/app-store.png').default} className="img-fluid downloadImg mr-3 cursor-pointer" />
                                <img src={require('../../assets/img/tools/ctrader/google-play.png').default} className="img-fluid downloadImg cursor-pointer" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-6 text-center order-1 order-md-2 mb-3'>
                            <img src={require('../../assets/img/tools/ctrader/ctraderMobile.png').default} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(CTrader);
