import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ParticlesBubble from '../Particles';

function CrudeOil(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className='product'>
                <div className='headerSection' id="crudeOil">
                    <div className='row justify-content-center justify-content-lg-start w-100 mx-0'>
                        <div className='col-12 col-lg-1' />
                        <div className='col-12 col-lg-5 text-center text-lg-left'>

                            <div className={props.lang === "ZH_CN" ? 'd-none' : 'd-block'}>
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Crude Oil Trading with")}</span><br />
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Vanguard Capital Group")}</span>
                            </div>
                            <div className={props.lang === "ZH_CN" ? 'd-block' : 'd-none'}>
                                <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Crude Oil Trading with")}</span><br />
                            </div>

                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Choose Vanguard Capital Group for safe, transparent, and flexible crude oil trading with low transaction costs and high capital utilization.")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Open Live Account")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection vh-min-100 d-flex align-items-center justify-content-center bg-white'>
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-8 col-lg-6 text-center mb-5'>
                            <img src={require('../../assets/img/product/crudeOil/detailsImg.png').default} className="img-fluid productDetailsImgSize mx-auto" />
                        </div>
                        <div className='col-12 col-md-10 col-lg-5 mb-4 text-center text-lg-left'>
                            <div className={props.lang === "ZH_CN" ? 'd-none' : 'd-block'}>
                                <span className='font-EN-Bold sectionProductTitle text-blue'>{translate(props.lang, "Traded Crude Oil Futures with ")}</span>
                                <span className='font-EN-Bold sectionProductTitle text-purple3'>{translate(props.lang, "Vanguard Capital Group")}</span>
                            </div>
                            <div className={props.lang === "ZH_CN" ? 'd-block' : 'd-none'}>
                                <span className='font-EN-Bold sectionProductTitle text-blue'>{translate(props.lang, "Traded Crude Oil Futures ")}</span>
                                <span className='font-EN-Bold sectionProductTitle text-purple3'>{translate(props.lang, "with vanguard capital group")}</span>
                            </div>


                            <div className='pt-4'>
                                <ul className='text-left'>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Benefit from the growing volume of crude oil trading with Vanguard Capital Group's professional service platform.")}</span>
                                    </li>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Choose from a variety of crude oil products such as London Brent Crude Oil (UKOUSD) and US Crude Oil (USOUSD) based on your market understanding.")}</span>
                                    </li>
                                    <li className='mb-3'>
                                        <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "Enjoy flexible trading with T+0 trading rules and easy-to-grasp supply and demand market information.")}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='productDetailsSection vh-min-100 d-flex align-items-center justify-content-center'>
                    <ParticlesBubble />
                    <div className='row justify-content-center align-items-center mx-0 py-5'>
                        <div className='col-12 col-md-10 col-lg-9 text-center'>
                            <span className='font-EN-Bold sectionTitle text-purple-light2'>{translate(props.lang, "At Vanguard ")}</span>
                            <span className='font-EN-Bold sectionTitle text-purple3'>{translate(props.lang, "Capital Group")}</span>

                            <div className='pt-5'>
                                <span className='font-EN-Regular headerSubTitle text-purple3'>{translate(props.lang, "We offers cost-effective crude oil trading with low spreads and transaction costs. Leverage transactions allow investors to maximize their returns and improve capital utilization. Understanding the market is made easy as crude oil prices are largely affected by supply and demand. Choose from a variety of crude oil products such as London Brent Crude Oil and US Crude Oil and experience flexible trading with T+0 trading rules.")}</span>
                            </div>

                            <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                <div className='learnMoreBtn mt-5 py-3 px-5 mx-auto cursor-pointer'>
                                    <span className=''>{translate(props.lang, "Start Trading Now")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(CrudeOil);
