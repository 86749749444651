import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

import advantageIcon1 from "../../assets/img/company/advantages/advantage1.png";
import advantageIcon2 from "../../assets/img/company/advantages/advantage2.png";
import advantageIcon3 from "../../assets/img/company/advantages/advantage3.png";
import advantageIcon4 from "../../assets/img/company/advantages/advantage4.png";
import advantageIcon5 from "../../assets/img/company/advantages/advantage5.png";
import advantageIcon6 from "../../assets/img/company/advantages/advantage6.png";

const advantagesArray = [
    { img: advantageIcon1, title: "Personalized Investment Strategies", desc: "We understand that every investor has unique needs and goals, which is why we offer personalized investment strategies tailored to each client's specific situation. We take the time to get to know our clients and their financial goals, so that we can help them make the best investment decisions for their individual needs." },
    { img: advantageIcon2, title: "Secure & Reliable Trading Environment", desc: "At Vanguard Capital Group, we take the security and reliability of our trading platform very seriously. We use the latest technology and security measures to ensure that our clients' funds and personal information are always safe and protected." },
    { img: advantageIcon3, title: "Exceptional Customer Service", desc: "Our commitment to exceptional customer service is what sets us apart from the competition. We're always here to answer any questions and address any concerns our clients may have, and we're always striving to provide the best possible experience for our clients." },
    { img: advantageIcon4, title: "Exceptional Investment Expertise", desc: "Our team of investment experts has years of experience in the industry, and we're always staying up-to-date on the latest trends and market movements. We use this expertise to help guide our clients in their investment decisions and to help them achieve their financial goals." },
    { img: advantageIcon5, title: "Powerful AI Trading", desc: "Our advanced artificial intelligence trading system is a powerful tool that helps us make smarter investment decisions. By leveraging data and algorithms, we can analyze market trends and make predictions about where the market is headed. This allows us to make informed decisions and execute trades quickly and efficiently." },
    { img: advantageIcon6, title: "Competitive Price", desc: "We understand that cost is an important factor when it comes to investing, which is why we offer some of the most competitive pricing in the industry. We believe that everyone should have access to quality investment services, regardless of their budget." },
]

function Advantages(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow companyAdNext p-2 cursor-pointer" id="nextArrow" onClick={onClick}>
                <FaAngleRight fontSize={30} color='#fff' />
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow companyAdPrev p-2 cursor-pointer" id="prevArrow" onClick={onClick}>
                <FaAngleLeft fontSize={30} color='#fff' />
            </div>
        );
    };

    const [imageIndex, setImageIndex] = React.useState(0);

    const settings = {
        dots: window.innerWidth > 677 ? false : true,
        infinite: true,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: true,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 3 : window.innerWidth > 677 ? 1 : 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        swipe: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <IndexNavbar />
            <div className='company'>
                <div className='headerSection' id="advantages">
                    <div className='row justify-content-center justify-content-lg-end w-100 mx-0'>
                        <div className='col-12 col-md-10 col-lg-5 text-center text-lg-left pr-2 pr-lg-5'>
                            <div className='pr-2 pr-lg-5'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "MANAGED PORTFOLIOS")}</span>
                                <div className='pt-3'>
                                    <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Let The expert trade for you")}</span><br />
                                </div>
                                <div className='pt-3'>
                                    <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Invest in a portfolio managed by experts to target the highest long-term returns for you risk appetite")}</span>
                                </div>
                                <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                                    <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                        <span className=''>{translate(props.lang, "MANAGED PORTFOLIOS")}</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='advantageDetailsSection w-100'>
                    <div className=''>
                        <div className='row justify-content-center align-items-center mx-0 py-5 w-100'>
                            <div className='col-12 col-md-10 col-lg-6 mb-4 text-center pt-5'>
                                <span className='font-EN-Bold sectionTitle text-white'>{translate(props.lang, "Why choose vanguard Capital Group")}</span>
                            </div>
                        </div>
                        <div className='row justify-content-center align-items-center mx-0 w-100  position-relative pb-5 pb-md-0'>
                            <div className='col-12 col-md-9 col-lg-10 mb-4 text-center'>
                                <div className='py-5'>
                                    <Slider {...settings} className="text-center" style={{ zIndex: "10", position: "sticky" }}>
                                        {
                                            advantagesArray.map((item, i) => (
                                                <div className={imageIndex === i ? "activeCompanyAdvantagesDiv" : "inactiveCompanyAdvantagesDiv"} >
                                                    <div className='companyAdvantagesContainer px-3'>
                                                        <div className='row justify-content-center align-items-center text-left companyAdvantagesHeaderSection py-4 py-md-0'>
                                                            <div className='col-12 col-md-9 order-2 order-md-1 text-center text-md-left'>
                                                                <span className='font-EN-Bold companyAdvantagesTitle'>{translate(props.lang, item.title)}</span>
                                                            </div>
                                                            <div className='col-5 col-md-3 order-1 order-md-2 mb-4'>
                                                                <img src={item.img} className='w-100' />
                                                            </div>
                                                        </div>
                                                        <div className='row justify-content-start align-items-start py-4 text-center text-lg-left companyAdvantagesDescSection'>
                                                            <div className='col-12'>
                                                                <span className='font-EN-Regular companyAdvantagesDesc'>{translate(props.lang, item.desc)}</span> <br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </Slider>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Advantages);
