import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { FaAngleLeft, FaAngleRight, FaFacebookF, FaInstagram, FaLinkedinIn, FaStar, FaTelegramPlane } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ParticlesBubble from './Particles';
import Lottie from 'react-lottie-player'
import lottieJson from '../assets/img/home/podium.json';

import commentUser1 from "../assets/img/home/commentUser1.png";
import commentUser2 from "../assets/img/home/commentUser2.png";
import commentUser3 from "../assets/img/home/commentUser3.png";
import commentUser4 from "../assets/img/home/commentUser4.png";
import commentUser5 from "../assets/img/home/commentUser5.png";

const commentArray = [
  { star: 5, authorPic: commentUser1, authorName: "Wayne Brandon", comment: "I have been working with this company for several months now and I couldn't be happier with the results. Their new project with the early bird bonus is a game-changer and has helped me earn even more commission. Highly recommended!" },
  { star: 5, authorPic: commentUser2, authorName: "Darnell Henry", comment: "As a new member, I was a bit hesitant to join this company, but I'm glad I did. Their new project with the early bird bonus is a fantastic opportunity for anyone looking to make some extra money. Plus, their support team is always available to answer any questions I have." },
  { star: 4, authorPic: commentUser3, authorName: "Juan Huang", comment: "I've been in the trading industry for years and I've never come across a project like this. The AI system has helped me make some smart trades. I highly recommend this company to anyone looking to boost their earnings." },
  { star: 5, authorPic: commentUser4, authorName: "Christina Amie", comment: "The early bird bonus really caught my attention. I decided to give it a try and I'm so glad I did. Not only did I earn more commission, but I also gained valuable knowledge about trading. Thank you, VCG!" },
  { star: 5, authorPic: commentUser5, authorName: "Ryanne Leo", comment: "If you're looking for a reliable and profitable project, look no further than VCG. Their early bird bonus is an incredible offer and their AI system is second to none. Plus, their team of agents is always willing to lend a helping hand. Highly recommended!" },
]

function Index(props) {
  const [selectedRobot, selectRobot] = React.useState(0);

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    // document.documentElement.classList.remove("nav-open");
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow Wnext cursor-pointer" id="nextArrow" onClick={onClick} style={{ backgroundColor: "transparent" }}>
        <FaAngleRight fontSize={30} color='#000' />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow Wprev cursor-pointer" id="prevArrow" onClick={onClick} style={{ backgroundColor: "transparent" }}>
        <FaAngleLeft fontSize={30} color='#000' />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = React.useState(0);
  const [imageIndex2, setImageIndex2] = React.useState(0);

  const settings = {
    dots: false,
    infinite: true,
    lazyLoad: 'ondemand',
    initOnLoad: true,
    nextSlidesToPreload: 1,
    autoplay: true,
    speed: 300,
    slidesToShow: window.innerWidth > 992 ? 3 : window.innerWidth > 677 ? 1 : 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    swipe: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => { setImageIndex(next) },
  };

  const settings2 = {
    dots: true,
    infinite: true,
    lazyLoad: 'ondemand',
    initOnLoad: true,
    nextSlidesToPreload: 1,
    autoplay: true,
    speed: 300,
    slidesToShow: window.innerWidth > 992 ? 3 : window.innerWidth > 677 ? 3 : 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    swipe: true,
    beforeChange: (current, next) => { setImageIndex2(next) },
  };

  return (
    <>
      <IndexNavbar />
      <div className='home'>
        <ParticlesBubble />
        <div className='headerSection'>
          <div className='row justify-content-center justify-content-lg-start w-100 mx-0'>
            <div className='col-12 col-lg-1' />
            <div className='col-12 col-lg-11 text-center text-lg-left'>
              <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Invest Wisely,")}</span><br />
              <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Trade Profitably")}</span>
              <div className='pt-4'>
                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "with our fund management solutions")}</span>
              </div>
              <a href='https://client.vcgtraders.com/login' target='_blank' className='footerNav'>
                <div className='learnMoreBtn mt-4 py-3 px-5 cursor-pointer'>
                  <span className=''>{translate(props.lang, "Get Started")}</span>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className='vanguardSection py-5'>
          <div className='row justify-content-center w-100 text-center py-5 mx-0'>
            <div className='col-12 col-md-10 col-lg-6'>
              <span className='font-EN-Bold sectionTitle text-purple'>{translate(props.lang, "Why vanguard Capital Group")}</span>
              <div className='pt-4'>
                <span className='font-EN-Regular headerSubTitle text-purple'>{translate(props.lang, "Discover the advantages of trading with Vanguard Capital Group and embark on a voyage to financial success")}</span>
              </div>
            </div>
          </div>

          <div className='mx-auto' style={{ maxWidth: "1300px" }}>
            <div className='row justify-content-center w-100 text-center mx-auto pt-3' >
              <div className='col-10 col-md-4 col-lg-3 mb-3'>
                <div className="vanguardDiv py-4 px-4 cursor-pointer" id="vanguardBg1">
                  <img src={require('../assets/img/home/vanguard1.png').default} className="img-fluid" />
                  <div className='pt-5 mt-5'>
                    <span className='font-EN-Medium vanguardBoxText text-purple2'>{translate(props.lang, "Instant Buying & Selling Quotation")}</span>
                  </div>
                </div>
              </div>
              <div className='col-10 col-md-4 col-lg-3 mb-3'>
                <div className="vanguardDiv py-4 px-4 cursor-pointer" id="vanguardBg2">
                  <img src={require('../assets/img/home/vanguard2.png').default} className="img-fluid" />
                  <div className='pt-5 mt-5'>
                    <span className='font-EN-Medium vanguardBoxText text-purple2'>{translate(props.lang, "Replicable transaction mode")}</span>
                  </div>
                </div>
              </div>

              <div className='col-10 col-md-4 col-lg-3 mb-3'>
                <div className="vanguardDiv py-4 px-4 cursor-pointer" id="vanguardBg3">
                  <img src={require('../assets/img/home/vanguard3.png').default} className="img-fluid" />
                  <div className='pt-5 mt-5'>
                    <span className='font-EN-Medium vanguardBoxText text-purple2'>{translate(props.lang, "intelligent expert adviser (ea)function")}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='row justify-content-center w-100 text-center mx-auto' >
              <div className='col-10 col-md-4 col-lg-3 mb-3'>
                <div className="vanguardDiv py-4 px-4 cursor-pointer" id="vanguardBg4">
                  <img src={require('../assets/img/home/vanguard4.png').default} className="img-fluid" />
                  <div className='pt-5 mt-5'>
                    <span className='font-EN-Medium vanguardBoxText text-purple2'>{translate(props.lang, "clear account status")}</span>
                  </div>
                </div>
              </div>

              <div className='col-10 col-md-4 col-lg-3 mb-3'>
                <div className="vanguardDiv py-4 px-4 cursor-pointer" id="vanguardBg5">
                  <img src={require('../assets/img/home/vanguard5.png').default} className="img-fluid" />
                  <div className='pt-5 mt-5'>
                    <span className='font-EN-Medium vanguardBoxText text-purple2'>{translate(props.lang, "multi-terminal system trading")}</span>
                  </div>
                </div>
              </div>

              <div className='col-10 col-md-4 col-lg-3 mb-3'>
                <div className="vanguardDiv py-4 px-4 cursor-pointer" id="vanguardBg6">
                  <img src={require('../assets/img/home/vanguard6.png').default} className="img-fluid" />
                  <div className='pt-5 mt-5'>
                    <span className='font-EN-Medium vanguardBoxText text-purple2'>{translate(props.lang, "advanced chatting tools")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='clientCommentSection py-5'>
          <div className='row justify-content-center w-100 text-center py-5 mx-0'>
            <div className='col-12 col-md-10 col-lg-6'>
              <span className='font-EN-Bold sectionTitle text-purple'>{translate(props.lang, "what our clients says")}</span>
              <div className='pt-4'>
                <span className='font-EN-Regular headerSubTitle text-purple'>{translate(props.lang, "Read our reviews To find out more about us")}</span>
              </div>
            </div>
          </div>

          <div className='position-relative'>
            <div className='row justify-content-center align-items-center mx-auto' style={{ maxWidth: "1400px" }}>
              <div className='col-11 col-md-11 col-lg-11'>
                <Slider {...settings} className="py-5 text-center" style={{ zIndex: "10", position: "sticky" }}>
                  {
                    commentArray.map((item, i) => (
                      <div className={imageIndex === i ? "activeCommentSlide clientCommentBox" : "inactiveCommentSlide clientCommentBox"} >
                        <div className='py-5 px-4'>
                          <div className='d-flex justify-content-center align-items-center'>
                            <FaStar fontSize={20} className='text-purple2 mx-1' />
                            <FaStar fontSize={20} className='text-purple2 mx-1' />
                            <FaStar fontSize={20} className='text-purple2 mx-1' />
                            <FaStar fontSize={20} className='text-purple2 mx-1' />
                            <FaStar fontSize={20} className='text-purple2 mx-1' style={{ display: i === 2 ? "none" : "block" }} />
                          </div>

                          <div className='pt-5 mt-5'>
                            <span className='userCommentText font-EN-Regular'>{translate(props.lang, item.comment)} </span>
                          </div>

                          <div className='pt-5 mt-5 text-center'>
                            <img src={item.authorPic} className="img-fluid userProfileSize mx-auto mb-2" />
                            <span className='userCommentName font-EN-Regular'>{translate(props.lang, item.authorName)}</span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </Slider>

              </div>
            </div>
          </div>
        </div>

        <div className='d-none d-lg-block'>
          <div className='robotDiv vh-min-100 position-relative d-flex align-items-end justify-content-center text-center w-100'>
            <div className='position-relative' style={{}}>
              <img src={require('../assets/img/home/robotImgTest.png').default} className="img-fluid robotMascotSize mb-5" style={{ display: selectedRobot === 0 ? 'block' : "none" }} />
              <img src={require('../assets/img/home/robot3ImgTest.png').default} className="img-fluid robotMascotSize mb-5" style={{ display: selectedRobot === 1 ? 'block' : "none" }} />
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: "150%", height: "auto", position:"absolute", bottom: "-4vh", left:"-25%" }}
              />
            </div>

            <div className='accountTypePos text-right'>
              <div className='row justify-content-end align-items-center'>
                <div className='col-5'>
                  <span className='font-EN-Bold accountTypeTitle text-white'>{translate(props.lang, "Our Account Type")}</span> <br /><br />
                  <span className='userCommentText font-EN-Regular text-white'>{translate(props.lang, "Our account types cater to traders of all levels, providing customized solutions to match individual trading goals and requirements")}</span>
                </div>
              </div>
            </div>
            <div className='accountTypeSelectPos text-left'>
              <div className='row justify-content-start align-items-center'>
                <div className='col-4'>
                  <div className='mb-5 pb-5'>
                    <div className='d-flex justify-content-start align-items-center cursor-pointer'>
                      <div className='activeTab' style={{ opacity: selectedRobot === 0 ? "1" : "0" }} />
                      <div className='ml-3'>
                        <span className={selectedRobot === 0 ? 'activeTabText' : "inactiveTabText"} onClick={() => selectRobot(0)}>{translate(props.lang, "Explorer")}</span>
                      </div>
                    </div>
                    <div className='d-flex justify-content-start align-items-center pt-3 cursor-pointer'>
                      <div className='activeTab' style={{ opacity: selectedRobot === 1 ? "1" : "0" }} />
                      <div className='ml-3'>
                        <span className={selectedRobot === 1 ? 'activeTabText' : "inactiveTabText"} onClick={() => selectRobot(1)}>{translate(props.lang, "Adventurer Plan")}</span>
                      </div>
                    </div>
                  </div>
                  <div className='pt-5 mt-5'>
                    <span className='userCommentText font-EN-Regular text-white'>
                      {selectedRobot === 0 ?
                        translate(props.lang, "This account is perfect for beginner traders who are just starting out. With low minimum deposits and competitive spreads, this account type provides an accessible and affordable way to enter the world of forex trading.")
                        :
                        translate(props.lang, "Adventurer Plan is perfect for experienced traders who demand the best and provides everything you need to stay ahead of the curve. With our team of expert traders on hand, you can be sure you're getting the most out of your trading experience.")
                      }</span>
                    <br /><br />
                    <a href='/trading/account-type' target='_blank'>
                      <span className='userCommentText font-EN-Regular text-white cursor-pointer' style={{ textDecoration: "underline" }}><b>{translate(props.lang, "Read More")}</b></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-block d-lg-none'>
          <div className='robotDiv position-relative d-flex align-items-end justify-content-center text-center w-100 py-5'>
            <div className='pb-5'>
            <div className='position-relative' style={{}}>
              <img src={require('../assets/img/home/robotImgTest.png').default} className="img-fluid robotMascotSize mb-5" style={{ display: selectedRobot === 0 ? 'block' : "none" }} />
              <img src={require('../assets/img/home/robot3ImgTest.png').default} className="img-fluid robotMascotSize mb-5" style={{ display: selectedRobot === 1 ? 'block' : "none" }} />
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: "100%", height: "auto", position:"absolute", bottom: "-5vh", left:"0%" }}
              />
            </div>

              <div className='text-left'>
                <div className='row justify-content-center align-items-center mx-0 mb-3'>
                  <div className='col-12 col-md-10'>
                    <span className='font-EN-Bold accountTypeTitle text-white'>{translate(props.lang, "Our Account Type")}</span> <br /><br />
                    <span className='userCommentText font-EN-Regular text-white'>{translate(props.lang, "Our account types cater to traders of all levels, providing customized solutions to match individual trading goals and requirements")}</span>
                  </div>
                </div>
              </div>

              <div className='text-left pt-3'>
                <div className='row justify-content-center align-items-center mx-0'>
                  <div className='col-11 col-md-10'>
                    <div className='mb-4'>
                      <div className='d-flex justify-content-start align-items-center'>
                        <div className='activeTab' style={{ opacity: selectedRobot === 0 ? "1" : "0" }} />
                        <div className='ml-3'>
                          <span className={selectedRobot === 0 ? 'activeTabText' : "inactiveTabText"} onClick={() => selectRobot(0)}>{translate(props.lang, "Explorer")}</span>
                        </div>
                      </div>
                      <div className='d-flex justify-content-start align-items-center pt-3'>
                        <div className='activeTab' style={{ opacity: selectedRobot === 1 ? "1" : "0" }} />
                        <div className='ml-3'>
                          <span className={selectedRobot === 1 ? 'activeTabText' : "inactiveTabText"} onClick={() => selectRobot(1)}>{translate(props.lang, "Adventurer Plan")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row justify-content-center align-items-center mx-0 text-left'>
                <div className='col-12 col-md-10'>
                  <span className='userCommentText font-EN-Regular text-white'>
                    {selectedRobot === 0 ?
                      translate(props.lang, "This account is perfect for beginner traders who are just starting out. With low minimum deposits and competitive spreads, this account type provides an accessible and affordable way to enter the world of forex trading.")
                      :
                      translate(props.lang, "Adventurer Plan is perfect for experienced traders who demand the best and provides everything you need to stay ahead of the curve. With our team of expert traders on hand, you can be sure you're getting the most out of your trading experience.")
                    }</span>
                  <br /><br />
                  <a href='/trading/account-type' target='_blank'>
                    <span className='userCommentText font-EN-Regular text-white cursor-pointer' style={{ textDecoration: "underline" }}><b>{translate(props.lang, "Read More")}</b></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='tradingBg vh-min-100 d-flex align-items-center'>
          <div className='row justify-content-center align-items-center mx-0'>
            <div className='col-12 col-md-11'>
              <div className='row justify-content-center align-items-start py-5'>
                <div className='col-12 col-lg-5'>
                  <div className='pt-0 pt-lg-5 text-center text-lg-left'>
                    <span className='font-EN-Bold sectionTitle text-white'>{translate(props.lang, "Powerful")}</span> <br />
                    <span className='font-EN-Bold sectionTitle text-white'>{translate(props.lang, "Trading Terminals")}</span>
                  </div>
                </div>
                <div className='col-12 col-lg-7'>
                  <div className='pt-5 mt-5'>
                    <img src={require('../assets/img/home/tradingPC.png').default} className="img-fluid w-100" />
                  </div>
                </div>
              </div>

              <div className='row justify-content-center align-items-center py-5 text-center'>
                <div className='col-6 col-md-4 col-lg-2 mb-4'>
                  <img src={require('../assets/img/home/trading1.png').default} className="img-fluid tradingOS_Logo" />
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-4'>
                  <img src={require('../assets/img/home/trading2.png').default} className="img-fluid tradingOS_Logo" />
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-4'>
                  <img src={require('../assets/img/home/trading3.png').default} className="img-fluid tradingOS_Logo" />
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-4'>
                  <img src={require('../assets/img/home/trading4.png').default} className="img-fluid tradingOS_Logo" />
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-4'>
                  <img src={require('../assets/img/home/trading5.png').default} className="img-fluid tradingOS_Logo" />
                </div>
                <div className='col-6 col-md-4 col-lg-2 mb-4'>
                  <img src={require('../assets/img/home/trading6.png').default} className="img-fluid tradingOS_Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='zeroCommissionDiv vh-min-100 d-flex align-items-center text-center position-relative bg-white'>
          <img src={require('../assets/img/home/zeroCommissionBg.png').default} className="img-fluid zeroCommissionSize mx-auto" />
          <div className='zeroCommissionContent row justify-content-center'>
            <div className='col-12 col-md-10 col-lg-8'>
              <span className='font-EN-Bold sectionTitle text-purple3'>{translate(props.lang, "Enjoy Zero Commission")}</span> <br /><br />
              <span className='userCommentText font-EN-Regular'>{translate(props.lang, "We are a professional forex custody broker committed to providing investors with safe, stable, and efficient services. We are pleased to announce that our platform does not charge any commission fees. We believe that this will make our platform more attractive and enable investors to trade more easily. Our platform uses the most advanced technology and security measures to ensure the protection of all users' funds and trading data. Our team has extensive experience and professional knowledge to provide you with professional investment advice and support. If you have any questions or need assistance, please feel free to contact our customer service team. Thank you for choosing our platform and we wish you a happy trading experience!")}</span>
            </div>
          </div>
        </div>

        <div className='awardsDiv vh-min-100 d-flex justify-content-center align-items-center text-center position-relative'>
          <div className='row justify-content-center w-100'>
            <div className='col-12 col-md-10 col-lg-6 mb-4'>
              <span className='font-EN-Bold sectionTitle text-white'>{translate(props.lang, "Start Your ")}</span>
              <span className='font-EN-Bold sectionTitle text-purple-light'>{translate(props.lang, "Trading ")}</span>
              <span className='font-EN-Bold sectionTitle text-white'>{translate(props.lang, "Journey With ")}</span>
              <span className='font-EN-Bold sectionTitle text-white'>{translate(props.lang, "An ")}</span>
              <span className='font-EN-Bold sectionTitle text-purple-light'>{translate(props.lang, "Award Winning ")}</span>
              <span className='font-EN-Bold sectionTitle text-white'>{translate(props.lang, "Broker")}</span>
            </div>
            <div className='col-12 col-lg-10'>
              <Slider {...settings2} className="py-5 text-center" arrows={false} style={{ zIndex: "10", position: "sticky" }}>
                <div className={imageIndex2 === 0 ? "" : ""} >
                  <img src={require('../assets/img/home/award1.png').default} className="img-fluid mx-auto awardsImgSize" />
                </div>
                <div className={imageIndex2 === 1 ? "" : ""} >
                  <img src={require('../assets/img/home/award2.png').default} className="img-fluid mx-auto awardsImgSize" />
                </div>
                <div className={imageIndex2 === 2 ? "" : ""} >
                  <img src={require('../assets/img/home/award3.png').default} className="img-fluid mx-auto awardsImgSize" />
                </div>
                <div className={imageIndex2 === 3 ? "" : ""} >
                  <img src={require('../assets/img/home/award1.png').default} className="img-fluid mx-auto awardsImgSize" />
                </div>
                <div className={imageIndex2 === 4 ? "" : ""} >
                  <img src={require('../assets/img/home/award2.png').default} className="img-fluid mx-auto awardsImgSize" />
                </div>
                <div className={imageIndex2 === 5 ? "" : ""} >
                  <img src={require('../assets/img/home/award3.png').default} className="img-fluid mx-auto awardsImgSize" />
                </div>

              </Slider>
            </div>
          </div>
        </div>
      </div>
      <DarkFooter />
    </>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(Index);
