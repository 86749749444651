const ZH_CN = {
    "Company": "公司",
    "About Us": "关于我们",
    "Advantages": "我们的优点",
    "AI Powered": "人工智能驱动",
    "Products": "产品",
    "Forex Exchange": "交易",
    "Precious Metal": "贵金属",
    "Crude Oil": "原油",
    "Index": "指数",
    "Contract For Difference": "差价合约",
    "Trading": "外汇",
    "VCG World Pool": "威创资本奖金池",
    "Account Type": "帐户类型",
    "Tools": "工具",
    "Market News": "市场资讯",
    "CTrader": "CTrader",
    "Support": "帮助",
    "Contact Us": "联系我们",
    "FAQS": "常问问题",
    "Login": "登录",
    "Sign Up": "注册",

    "Ready to get started?": "准备好开始了吗？",
    "Get Started": "开始",
    "Contact VCG": "联络威创资本",
    "Enter Your Email Address": "请输入你的电子邮箱地址",

    "Invest Wisely,": "明智的投资",
    "Trade Profitably": "有利可图的交易",
    "with our fund management solutions": "使用我们的基金管理方案",
    "Why vanguard Capital Group": "为什么选择威创资本集团",
    "Discover the advantages of trading with Vanguard Capital Group and embark on a voyage to financial success": "发现与威创资本集团交易的优势，踏上通往财务成功的航程",
    "Instant Buying & Selling Quotation": "即时买卖报价",
    "Replicable transaction mode": "可复制交易模式",
    "intelligent expert adviser (ea)function": "智能专家顾问（EA）功能",
    "clear account status": "透明账户状态",
    "multi-terminal system trading": "多终端系统交易",
    "advanced chatting tools": "高级聊天工具",
    "what our clients says": "我们客户的反馈",
    "Read our reviews To find out more about us": "阅读我们的评论以了解更多关于我们的信息",
    "All is working well. Only one week in and i very pleased to have joined VCG.": "一切正常。仅仅一个星期，我就很高兴加入了威创资本。",
    "Our Account Type": "我们的账户类型",
    "Our account types cater to traders of all levels, providing customized solutions to match individual trading goals and requirements": "我们的账户类型适合所有级别的交易者，提供定制的解决方案以满足个人交易目标和要求",
    "Explorer": "探险家计划",
    "Adventurer Plan": "冒险家计划",
    "This account is perfect for beginner traders who are just starting out. With low minimum deposits and competitive spreads, this account type provides an accessible and affordable way to enter the world of forex trading.": "这个账户非常适合刚起步的新手交易者。凭借较低的最低存款额和具有竞争力的点差，这种账户类型提供了一种进入外汇交易世界的便捷且负担得起的方式。",
    "Adventurer Plan is perfect for experienced traders who demand the best and provides everything you need to stay ahead of the curve. With our team of expert traders on hand, you can be sure you're getting the most out of your trading experience.": "冒险家计划将提供更多选项和功能，帮助您在外汇交易中取得成功。请继续关注这计划更新，并在它推出的第一时间让您知道。",
    "Read More": "了解更多",
    "Powerful": "强大的",
    "Trading Terminals": "交易终端",
    "Enjoy Zero Commission": '享受"零"佣金',
    "We are a professional forex custody broker committed to providing investors with safe, stable, and efficient services. We are pleased to announce that our platform does not charge any commission fees. We believe that this will make our platform more attractive and enable investors to trade more easily. Our platform uses the most advanced technology and security measures to ensure the protection of all users' funds and trading data. Our team has extensive experience and professional knowledge to provide you with professional investment advice and support. If you have any questions or need assistance, please feel free to contact our customer service team. Thank you for choosing our platform and we wish you a happy trading experience!": "我们是专业的外汇托管经纪商，致力于为投资者提供安全、稳定、高效的服务。我们很高兴地宣布，我们的平台不收取任何佣金。我们相信这将使我们的平台更具吸引力，并使投资者能够更轻松地进行交易。我们的平台使用最先进的技术和安全措施来确保所有用户的资金和交易数据得到保护。我们的团队拥有丰富的经验和专业知识，为您提供专业的投资建议和支持。如果您有任何疑问或需要帮助，请随时联系我们的客户服务团队。感谢您选择我们的平台，祝您交易愉快！",
    "Start Your ": "使用一个",
    "Trading ": "屡获殊荣的",
    "Journey With ": "经纪商，",
    "An ": "开始你的",
    "Award Winning ": "外汇投资",
    "Broker": "旅程",

    "MANAGED PORTFOLIOS": "管理投资组合",
    "Let The expert trade for you": "让专家为您交易",
    "Invest in a portfolio managed by experts to target the highest long-term returns for you risk appetite": "选择由专业团队管理的投资组合，旨在为您的风险偏好设定最优的长期回报",
    "Vanguard Capital Group, was established in 2005 and we are a professional and advanced forex broker. We have a total of 500,000+ users. Our mission as a broker is to provide our clients with a secure and reliable trading environment, while also offering exceptional customer service and competitive pricing.": "威创资本集团，成立于2005年，是一家专业先进的外汇经纪商。我们共有 500,000 多个用户。作为经纪商，我们的使命是为我们的客户提供安全可靠的交易环境，同时提供卓越的客户服务和有竞争力的价格。",
    "As we continue to grow and expand our services, our future development goals include a focus on continuous innovation and the development of new technologies to improve our clients' trading experience. We are committed to staying ahead of the curve and ensuring that our clients have access to the latest tools and resources in the industry. At Vanguard Capital Group, we are dedicated to providing our clients with the best possible trading experience and helping them achieve their financial goals.": "随着我们不断发展和扩大我们的服务，我们未来的发展目标包括专注于持续创新和开发新技术以改善客户的交易体验。我们致力于保持领先地位，并确保我们的客户能够获得业内最新的工具和资源。在威创资本集团，我们致力于为客户提供最佳交易体验，帮助他们实现财务目标。",
    "Our Vision & Mission": "我们的愿景与使命",
    "Vision": "愿景",
    "Become an internationally leading intelligent investment management company": "成为国际领先的智能投资管理公司",
    "Provide efficient, safe, and intelligent asset management services to global investors.": "为全球投资者提供高效、安全、智能的资产管理服务",
    "Mission": "使命",
    "Help investors achieve long-term and stable wealth growth": "帮助投资者实现长期稳定的财富增长",
    "Provide intelligent investment concepts and innovative technologies": "提供智能投资理念和创新技术",

    "Why choose vanguard Capital Group": "为什么选择威创资本集团",
    "Personalized Investment Strategies": "个性化投资策略",
    "We understand that every investor has unique needs and goals, which is why we offer personalized investment strategies tailored to each client's specific situation. We take the time to get to know our clients and their financial goals, so that we can help them make the best investment decisions for their individual needs.": "我们了解每个投资者都有独特的需求和目标，这就是为什么我们提供针对每个客户的具体情况量身定制的个性化投资策略。我们花时间了解我们的客户和他们的财务目标，以便我们可以帮助他们根据他们的个人需求做出最佳投资决策。",
    "Secure & Reliable Trading Environment": "安全可靠的交易环境",
    "At Vanguard Capital Group, we take the security and reliability of our trading platform very seriously. We use the latest technology and security measures to ensure that our clients' funds and personal information are always safe and protected.": "在威创资本集团，我们非常重视交易平台的安全性和可靠性。我们使用最新的技术和安全措施来确保我们客户的资金和个人信息始终安全并受到保护。",
    "Exceptional Customer Service": "卓越的客户服务",
    "Our commitment to exceptional customer service is what sets us apart from the competition. We're always here to answer any questions and address any concerns our clients may have, and we're always striving to provide the best possible experience for our clients.": "我们对卓越客户服务的承诺使我们在竞争中脱颖而出。我们始终在这里回答任何问题并解决客户可能遇到的任何疑虑，并且我们始终努力为客户提供最佳体验。",
    "Exceptional Investment Expertise": "卓越的投资专长",
    "Our team of investment experts has years of experience in the industry, and we're always staying up-to-date on the latest trends and market movements. We use this expertise to help guide our clients in their investment decisions and to help them achieve their financial goals.": "我们的投资专家团队拥有多年的行业经验，我们始终紧跟最新趋势和市场动向。我们利用这些专业知识来帮助指导我们的客户做出投资决策，并帮助他们实现财务目标。",
    "Powerful AI Trading": "强大的人工智能交易",
    "Our advanced artificial intelligence trading system is a powerful tool that helps us make smarter investment decisions. By leveraging data and algorithms, we can analyze market trends and make predictions about where the market is headed. This allows us to make informed decisions and execute trades quickly and efficiently.": "我们先进的人工智能交易系统是一个强大的工具，可以帮助我们做出更明智的投资决策。通过利用数据和算法，我们可以分析市场趋势并预测市场走向。这使我们能够做出明智的决定并快速有效地执行交易。",
    "Competitive Price": "有竞争力的价格",
    "We understand that cost is an important factor when it comes to investing, which is why we offer some of the most competitive pricing in the industry. We believe that everyone should have access to quality investment services, regardless of their budget.": "我们知道成本是投资的一个重要因素，这就是为什么我们提供一些业内最具竞争力的价格。我们相信，无论预算如何，每个人都应该能够获得优质的投资服务。",

    "AI - Powered Forex Trading": "人工智能驱动的外汇交易",
    "Revolutionizing the way you trade": "彻底改变您的交易方式",
    "Open Live Account": "开设真实账户",
    "Advantages of ": "人工智能驱动的",
    "AI - Powered Forex Automatic Trading ": "外汇自动交易的优势",
    "Accuracy": "准确性",
    "AI-powered forex automatic trading can analyze more market data and make more accurate decisions based on data, improving the success rate of trades.": "人工智能驱动的外汇自动交易可以分析更多的市场数据，并根据数据做出更准确的决策，提高交易的成功率。",
    "Efficiency": "效率",
    "AI-powered forex automatic trading can execute trades quickly, avoiding the impact of human factors, and can simultaneously monitor multiple markets to improve trading efficiency": "人工智能驱动的外汇自动交易可以快速执行交易，避免人为因素的影响，并可以同时监控多个市场以提高交易效率",
    "Risk Management": "风险管理",
    "AI-powered forex automatic trading can execute trades based on preset risk management rules, avoiding the risks associated with emotional trading and decision-making errors": "人工智能驱动的外汇自动交易可以根据预设的风险管理规则执行交易，避免与情绪交易和决策失误相关的风险",
    "Adaptability": "适应性",
    "AI-powered forex automatic trading can adjust its trading strategy to market changes, maintaining its adaptability and effectiveness.": "人工智能驱动的外汇自动交易可以根据市场变化调整交易策略，保持其适应性和有效性。",
    "AI-Powered Forex ": "人工智能驱动的外汇",
    "Automatic Trading": "自动交易",
    "AI-powered forex automatic trading is a revolutionary technological advancement that offers investors an efficient, accurate, and risk-controlled trading experience. If you have any questions or need assistance regarding AI-powered forex automatic trading, please contact our customer service team, and we will be happy to assist you.": "人工智能驱动的外汇自动交易是一项革命性的技术进步，为投资者提供高效、准确和风险可控的交易体验。如果您对人工智能驱动的外汇自动交易有任何疑问或需要帮助，请联系我们的客户服务团队，我们将很乐意为您提供帮助。",
    "Start Trading Now": "立即开始交易",

    "Forex Exchange - Trade": "轻松交易全球货币",
    "Global currencies with Ease": "",
    "Invest in the largest financial market in the world": "投资全球最大的金融市场",
    "What Is ": "什么是",
    "Foreign Exchange?": "外汇？",
    "Forex trading is the exchange of one country's currency with another. At present, the Foreign exchange rate is mainly affected by international trade, national economy and the investment flows policy. As a result, the foreign exchange market information is more transparent, liquidity is better and there are more fair prices. Buy or sell transactions can be completed for 5×24 hours. All the above advantages make Foreign Exchange transaction deeply affected by large investment institutions and investors.": "外汇交易是一国货币与另一国货币的交换。目前，外汇汇率主要受国际贸易、国民经济和投资流动政策的影响。从而使外汇市场信息更加透明，流动性更好，价格更加公允。买入或卖出交易可在5×24小时内完成。以上种种优势使得外汇交易深受大型投资机构和投资者的青睐。",
    "At Vanguard ": "在威创",
    "Capital Group": "资本集团",
    "We offer a range of Forex trading options to meet your unique investment needs and goals. Our advanced trading platform and expert advisors provide you with the tools and resources you need to make informed trading decisions and maximize your profits. Start trading global currencies with ease today.": "我们提供一系列的外汇交易选项，以满足您独特的投资需求和目标。我们先进的交易平台和专业顾问为您提供做出明智交易决策和最大化利润所需的工具和资源。立即开始轻松交易全球货币。",

    "Precious Metal Trading with ": "贵金属交易-使用",
    "Vanguard Capital Group": "威创资本集团",
    "We offers professional and transparent trading services for precious metal": "我们提供专业透明的贵金属交易服务",
    "Protect & Grow Your Wealth with ": "通过贵金属交易可保护",
    "Precious Metal Trading": "并增加您的财富",
    "Precious metals provide protection against currency inflation.": "贵金属提供抵御货币通胀的保护。",
    "Investors can benefit from price differences and maintain value during economic downturns.": "投资者可以从价格差异中获益，并在经济低迷时期保持价值。",
    "Investors can make investment decisions based on their preferences.": "投资者可以根据自己的喜好做出投资决策。",
    "We are committed to providing our clients with the advantages of precious metal trading and helping them achieve their financial goals. Our platform offers a safe and transparent environment for investors to trade precious metals, ensuring that they have access to the latest tools and resources in the industry.": "我们致力于为我们的客户提供贵金属交易的优势，并帮助他们实现他们的财务目标。我们的平台为投资者提供安全透明的贵金属交易环境，确保他们能够获得业内最新的工具和资源。",

    "Crude Oil Trading with": "原油交易-使用威创资本集团",
    "Choose Vanguard Capital Group for safe, transparent, and flexible crude oil trading with low transaction costs and high capital utilization.": "选择威创资本集团进行安全、透明、灵活的原油交易，交易成本低，资金利用率高。",
    "Traded Crude Oil Futures with ": "交易原油期货与",
    "Traded Crude Oil Futures ": "与威创资本集团",
    "with vanguard capital group": "交易原油期货",
    "Benefit from the growing volume of crude oil trading with Vanguard Capital Group's professional service platform.": "通过威创资本集团的专业服务平台，从不断增长的原油交易量中获益。",
    "Choose from a variety of crude oil products such as London Brent Crude Oil (UKOUSD) and US Crude Oil (USOUSD) based on your market understanding.": "根据您对市场的了解，选择伦敦布伦特原油 (UKOUSD) 和美国原油 (USOUSD) 等多种原油产品。",
    "Enjoy flexible trading with T+0 trading rules and easy-to-grasp supply and demand market information.": "享受T+0交易规则的灵活交易，供求市场信息一目了然。",
    "We offers cost-effective crude oil trading with low spreads and transaction costs. Leverage transactions allow investors to maximize their returns and improve capital utilization. Understanding the market is made easy as crude oil prices are largely affected by supply and demand. Choose from a variety of crude oil products such as London Brent Crude Oil and US Crude Oil and experience flexible trading with T+0 trading rules.": "我们以低点差和交易成本提供具有成本效益的原油交易。杠杆交易可以让投资者获得最大收益，提高资金利用率。了解市场变得容易，因为原油价格在很大程度上受供求关系的影响。伦敦布伦特原油、美国原油等多种原油产品供您选择，体验T+0交易规则的灵活交易。",

    "Index Trading with": "指数交易-使用威创资本集团",
    "Discover the derivative market of Index trading with Vanguard Capital Group.": "与威创资本集团一起探索指数交易的衍生品市场。",
    "Trade with ": "交换",
    "Effective Risk Control & Flexible Leverage": "有效的风险控制和灵活的杠杆",
    "Index Trading is a derivative market based on stock index trading, providing investors with an opportunity to speculate on the price fluctuations of relevant futures quoted by the exchange. At Vanguard Capital Group, we offer a variety of index CFDs with low margin and competitive spreads, allowing traders to effectively manage their risks while enjoying the benefits of flexible leverage.": "指数交易是以股指交易为基础的衍生品市场，为投资者提供了对交易所报价的相关期货价格波动进行投机的机会。在威创资本集团，我们提供各种具有低保证金和具有竞争力的点差的指数差价合约，使交易者能够有效地管理他们的风险，同时享受灵活杠杆的好处。",

    "CFD Trading with ": "差价合约-使用威创资本集团",
    "Learn about CFD Trading with Vanguard Capital Group, a professional service platform that provides customers with safe, transparent trading opportunities.": "了解威创资本集团的差价合约交易，这是一个专业的服务平台，为客户提供安全、透明的交易机会。",
    "Trade CFD with ": "交易差价合约与",
    "Confidence": "信任",
    "CFDs are an emerging financial derivative that allows traders to speculate on the price of an underlying asset without actually owning it.": "差价合约是一种新兴的金融衍生品，允许交易者在不实际拥有标的资产的情况下推测标的资产的价格。",
    "THE ADVANTAGES OF CFD TRADING:": "差价合约交易的优势：",
    "Margin Trading": "保证金交易",
    "Tradable in Rising and Falling Markets": "可在上涨和下跌市场交易",
    "T+0 Trading Mode": "T+0交易模式",
    "Powerful Leverage Trading Model": "强大的杠杆交易模式",
    "Discover the benefits of Contract for Difference (CFD) trading with Vanguard Capital Group. CFDs are an emerging financial derivative traded at the price of a commodity, without the transaction of the commodity entity. With powerful leverage trading models and the ability to trade in rising and falling markets, CFD trading offers flexible opportunities for investors.": "了解与威创资本集团进行差价合约 (CFD) 交易的好处。差价合约是一种新兴的金融衍生品，以商品的价格进行交易，无需商品实体的交易。凭借强大的杠杆交易模型和在上涨和下跌市场中进行交易的能力，差价合约交易为投资者提供了灵活的机会。",

    "VCG World Pool Benefit Graph & User Leaderboard": "威创资本集团奖金池收益图表和用户排行榜",
    "Check out the VCG World Pool Benefit Graph and User Leaderboard to track your performance and see how you compare to other traders on the platform.": "查看威创资本集团奖金池收益图表和用户排行榜以跟踪您的表现并查看您与平台上其他交易者的比较情况。",

    "Choose the perfect account type for your trading needs": "选择最适合您交易需求的账户类型",
    "Explorer Plan": "探险家计划",
    "Lock": "定存",
    "1/2 Month": "1/2个月",
    "1 Month": "1个月",
    "3 Month": "3个月",
    "6 Month": "6个月",
    "9 Month": "9个月",
    "Coming Soon": "即将推出",
    "90 and above": "100美元及以上",
    "150 and above": "150美元及以上",
    "300 and above": "300美元及以上",
    "500 and above": "500美元及以上",
    "5 USD": "5美元",
    "15 USD": "15美元",
    "Month": "个月",
    "Deposit": "存款",
    "ROI": "投资回报率",
    "Referral Bonus": "推荐奖金",
    "Customer Profit": "客户利润",
    "Profit Sharing": "利润分享",
    "Management Fees": "管理费",
    "Withdrawal Period": "提取期",
    "Within 3 Working Days": "每月1日至5日",
    "Start Trading": "开始交易",
    "Start trading with Vanguard Capital Group in 4 Steps": "通过 4 个步骤开始与威创资本集团进行交易",
    "Register": "注册",
    "Verify": "验证",
    "Trade": "交易",

    "View All News": "查看所有新闻",
    "USD/CHF - Swiss franc climb higher": "美元/瑞士法郎 - 瑞士法郎走高",
    "Traders and investors use trading news to make informed decisions about buying and selling securities, currencies, commodities, and other financial instruments.": "交易员和投资者使用交易新闻做出有关买卖证券、货币、商品和其他金融工具的明智决定。",
    "10 hours": "10 小时",

    "cTrader is a complete trading platform solution for Forex and CFD brokers to offer their traders. The platform is packed with a full range of features to cater to each and every investment preference imaginable.": "cTrader是一个全面的交易平台，供外汇和差价合约经纪商为交易者提供服务。该平台拥有丰富的功能，可满足各种投资偏好。",
    "Trading with Vanguard Capital Group CTrader": "使用威创资本集团CTrader 进行交易",
    "A Premium Charting and Manual Trading PlatformcTrader is a leading multi-asset Forex and CFD trading platform, offering rich charting tools, advanced order types, level II pricing, and fast entry and execution. With a stunning user interface, it’s connected to the most sophisticated backend technology, and made available on multiple": "高级图表和手动交易平台cTrader 是领先的多资产外汇和差价合约交易平台，提供丰富的图表工具、高级订单类型、II 级定价以及快速进入和执行。凭借令人惊叹的用户界面，它连接到最先进的后端技术，并可在多种平台上使用",
    "CTrader Web": "CTrader 网站",
    "with CTrader Web, users can access all the features of cTrader from any popular browser without the need for installation. It's a fast and easy way to trade on desktops running Windows, Mac, or Linux operating systems.": "借助 CTrader 网站，用户无需安装即可从任何流行的浏览器访问 cTrader 的所有功能。这是在运行 Windows、Mac 或 Linux 操作系统的台式机上进行交易的一种快速简便的方法。",
    "Explore Now": "立即探索",
    "CTrader Mobile": "CTrader 手机版",
    "Traders can download the native cTrader app from either the App Store or Google Play Store for their iOS or Android mobile devices. The tablet-optimized apps offer a variety of key features for effective on-the-go trading and analysis from anywhere in the world.": "交易者可以从 App Store 或 Google Play Store 下载原生的 cTrader 应用程序，用于他们的 iOS 或 Android 移动设备。针对平板电脑优化的应用程序提供了多种关键功能，可在世界任何地方进行有效的移动交易和分析。",

    "5*24 hours online customer service providing online support & assistance": "7/24小时在线客服提供在线支持与帮助",
    "Name": '姓名',
    "Your Name": "你的名字",
    "Your Email": "你的邮件",
    "Phone Number": "电话号码",
    "Your phone number": "你的电话号码",
    "Submit": "提交",

    "Get answer to all your questions about trading, account management, regulations and more with our comprehensive FAQ section": "通过我们全面的常见问题解答部分，获得有关交易、账户管理、法规等所有问题的答案",
    "How to start trading?": "如何开始交易？",
    "VCG account type": "VCG账户类型",
    "Registering for a VCG account is just the first step in your trading journey! There are many activities you can participate in after registration. To learn more about VCG's range of services, please click here. (Include Referral Info link)": "注册VCG账户只是您交易之旅的第一步！注册后可以参加很多活动。要了解有关 VCG 服务范围的更多信息，请单击此处。 （包括推荐信息链接）",
    "Maximum investment limit for the plans": "计划的最高投资限额",
    "There is no limit to the investment amount, clients can freely choose the investment amount according to their financial strength and investment needs. The unlimited investment amount means that clients can invest any amount of funds into investment projects to obtain higher investment returns and profits. At the same time, clients also need to make prudent investment decisions based on their risk tolerance and financial planning to ensure a balance between investment risks and returns": "投资金额不限，客户可根据自身经济实力及投资需要自由选择投资金额。无限投资额度是指客户可以将任意数量的资金投入到投资项目中，以获得更高的投资回报和利润。同时，客户也需要根据自身的风险承受能力和财务规划做出审慎的投资决策，确保投资风险和收益的平衡。",
    "Minimum deposit amount": "最低入金金额",
    "The minimum deposit amount varies depending on the company, account type, and investment plan selected. It is important to remind clients that the principal invested in the investment plan will directly determine the rate of return. If clients have a specific amount they would like to earn, it is recommended to seek the assistance of an agent.": "最低入金金额因公司、账户类型和所选投资计划而异。谨此提醒客户，投资计划所投入的本金将直接决定回报率。如果客户有具体的收入要求，建议寻求代理人的帮助。",
    "How do I deposit and withdraw funds?": "我如何存入和提取资金？",
    "You can use various payment methods to deposit and withdraw your funds, such as bank transfers, electronic payments, and digital currencies, etc. You can choose your preferred payment method in your trading account and follow the prompts to complete the operation": "您可以使用银行转账、电子支付、数字货币等多种支付方式存取款，您可以在您的交易账户中选择您喜欢的支付方式，并按照提示完成操作。",
    "What is investment net value?": "什么是投资净值？",
    "Investment net value is the current value of the funds in an investment, which is the sum of the initial investment amount and the results of all orders (minus paid commissions). Investment net value = investment amount + investment returns - paid commissions. For example: If an investor invests $1,000 with a commission rate of 20%, and after three months the results of all orders is $200, with a commission of $40 paid to the investment manager, the investment net value is $1,160 ($1,000 + $200 - $40).": "投资净值是投资中资金的现值，即初始投资金额与所有订单的结果（减去支付的佣金）之和。投资净值=投资金额+投资收益-支付的佣金。例如：投资者投资1000美元，佣金率为20%，三个月后所有订单的结果为200美元，向投资经理支付40美元的佣金，则投资净值为1160美元（1000美元+200美元-） 40 美元）。",
    "What should i do if the trading platform is not functioning properly?": "交易平台运行不正常怎么办?",
    "If you encounter any problems while using the trading platform, please contact our technical support team. Our technical support staff will help you resolve the issue as soon as possible.": "如果您在使用交易平台过程中遇到任何问题，请联系我们的技术支持团队。我们的技术支持人员将尽快帮助您解决问题。",
    "What should i do if i am unable to log in to the trading platform?": "无法登录交易平台怎么办？",
    "If you are unable to log in to the trading platform, please ensure that your username and password are correct. If you still cannot log in, try resetting your password. If the issue persists, please contact our customer service team for further assistance.": "如果您无法登录交易平台，请确保您的用户名和密码正确。如果仍然无法登录，请尝试重置密码。如果问题仍然存在，请联系我们的客户服务团队以获得进一步的帮助。",
    "What should i do if i have lost my trading password?": "交易密码遗失怎么办？",
    "Please contact our customer service team and they will guide you through the password reset process. After you have been verified, we will send a password reset link to your registered email address.": "请联系我们的客户服务团队，他们将指导您完成密码重置过程。在您通过验证后，我们将向您注册的电子邮件地址发送一个密码重置链接。",
    "How can i change my trading account password?": "我如何更改我的交易账户密码？",
    "You can change your trading account password in the account settings of the trading platform. The specific process may vary depending on the trading platform. Please refer to the trading platform's documentation or contact customer service for assistance": "您可以在交易平台的账户设置中更改您的交易账户密码。具体流程可能因交易平台而异。请参考交易平台的文档或联系客服寻求帮助。",
    "Is my trading account protected?": "我的交易账户是否受到保护?",
    "Brokers provide customers with measures for fund protection and account security. These measures may include fund segregation, multi-factor authentication, etc. If you have any questions about the protection measures for your trading account, you can consult customer service representatives": "经纪商为客户提供资金保护和账户安全的措施。这些措施可能包括资金隔离、多因素认证等。如果您对交易账户的保护措施有任何疑问，可以咨询客户服务代表",
    "How can I change my account information?": "如何更改我的帐户信息？",
    "If you need to change your account information, such as email address, phone number, or bank account information, you can log in to your trading platform or contact customer service representatives to make the changes.": "如果您需要更改您的账户信息，例如电子邮件地址、电话号码或银行账户信息，您可以登录您的交易平台或联系客户服务代表进行更改。",

    "Access the derivative market based on stock index trading.": "进入基于股指交易的衍生品市场。",
    "The index CFDs reflect the price fluctuations of relevant futures quoted by the exchange.": "指数差价合约反映了交易所报价的相关期货的价格波动。",
    "Low margin and low cost make Index Trading cost-effective.": "低保证金和低成本使指数交易具有成本效益。",
    "Enjoy competitive spread and a variety of trading samples.": "享受有竞争力的点差和多种交易样本。",
    "Flexible leverage allows investors to maximize their returns.": "灵活的杠杆使投资者能够最大化他们的回报。",
    "$800 & Above": "$800及以上",

    "I have been working with this company for several months now and I couldn't be happier with the results. Their new project with the early bird bonus is a game-changer and has helped me earn even more commission. Highly recommended!": "我已经在这家公司工作了几个月，我对结果非常满意。他们提供早鸟奖金的新项目改变了游戏规则，帮助我赚取了更多佣金。强烈推荐！",
    "As a new member, I was a bit hesitant to join this company, but I'm glad I did. Their new project with the early bird bonus is a fantastic opportunity for anyone looking to make some extra money. Plus, their support team is always available to answer any questions I have.": "作为一名新成员，我对加入这家公司有点犹豫，但我很高兴我加入了。他们的新项目提供早起的鸟儿奖金，对于任何想赚外快的人来说都是一个绝佳的机会。另外，他们的支持团队随时可以回答我的任何问题。",
    "I've been in the trading industry for years and I've never come across a project like this. The AI system has helped me make some smart trades. I highly recommend this company to anyone looking to boost their earnings.": "我从事贸易行业多年，从未遇到过这样的项目。人工智能系统帮助我进行了一些明智的交易。我强烈推荐这家公司给任何希望增加收入的人。",
    "The early bird bonus really caught my attention. I decided to give it a try and I'm so glad I did. Not only did I earn more commission, but I also gained valuable knowledge about trading. Thank you, VCG!": "早起的鸟儿奖金真的引起了我的注意。我决定试一试，我很高兴我做到了。我不仅赚取了更多的佣金，而且还获得了宝贵的交易知识。谢谢你，VCG！",
    "If you're looking for a reliable and profitable project, look no further than VCG. Their early bird bonus is an incredible offer and their AI system is second to none. Plus, their team of agents is always willing to lend a helping hand. Highly recommended!": "如果您正在寻找可靠且有利可图的项目，VCG 是您的不二之选。他们的早鸟奖金是一个令人难以置信的优惠，他们的 AI 系统是首屈一指的。另外，他们的代理团队总是愿意伸出援助之手。强烈推荐！"
}

export default ZH_CN;