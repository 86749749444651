import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import { Input } from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ParticlesBubble from '../Particles';

function ContactUs(props) {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        // document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className='support'>
                <div className='headerSection'>
                    <div className='row justify-content-center justify-content-lg-start align-items-center w-100 mx-0 py-5 my-5'>
                        <div className='col-12 col-lg-1' />
                        <div className='col-12 col-lg-4 text-center text-lg-left my-5'>
                            <span className='font-EN-Bold headerTitle text-white'>{translate(props.lang, "Contact Us")}</span><br />
                            <div className='pt-3'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "5*24 hours online customer service providing online support & assistance")}</span>
                            </div>
                        </div>
                        <div className='col-12 col-lg-2 mb-5' />

                        <div className='col-12 col-md-10 col-lg-4 text-left'>
                            <div className='mb-4'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Name")}</span> <br />
                                <Input
                                    placeholder={translate(props.lang, "Your Name")}
                                    type="text"
                                    // name="email"
                                    // value={values.firstName}
                                    // onChange={handleChange("firstName")}
                                    className="contactUs-textBox py-3"
                                />
                            </div>
                            <div className='mb-4'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Your Email")}</span> <br />
                                <Input
                                    placeholder={translate(props.lang, "Your Email")}
                                    type="text"
                                    // name="email"
                                    // value={values.firstName}
                                    // onChange={handleChange("firstName")}
                                    className="contactUs-textBox py-3"
                                />
                            </div>
                            <div className='mb-4'>
                                <span className='font-EN-Regular headerSubTitle text-white'>{translate(props.lang, "Phone Number")}</span> <br />
                                <Input
                                    placeholder={translate(props.lang, "Your phone number")}
                                    type="text"
                                    // name="email"
                                    // value={values.firstName}
                                    // onChange={handleChange("firstName")}
                                    className="contactUs-textBox py-3"
                                />
                            </div>
                            <div className='learnMoreBtn mt-5 py-3 px-5 cursor-pointer'>
                                <span className=''>{translate(props.lang, "Submit")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(ContactUs);
