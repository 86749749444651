/*eslint-disable*/
import React from "react";
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import { FaArrowRight, FaFacebookF, FaInstagram, FaLinkedinIn, FaTelegramPlane } from 'react-icons/fa';
import { Input } from "reactstrap";

function DarkFooter(props) {
  return (
    <footer className="footer py-4">
      <div className="row justify-content-center align-items-center mx-0">
        <div className="col-12 col-md-12 col-lg-12">
          <div className='getStartedDiv'>
            <div className='row justify-content-center w-100 mx-0'>
              <div className='col-12 col-md-12 col-lg-9'>
                <div className='my-5 py-5 getStartedBox'>
                  <div className='row justify-content-center align-items-center w-100 mx-0'>
                    <div className='col-12 col-md-7 text-center text-lg-left'>
                      <span className='font-EN-Bold getStartedTitle text-white'>{translate(props.lang, "Ready to get started?")}</span>
                    </div>
                    <div className='col-12 col-md-4'>
                      <a href="https://client.vcgtraders.com/login" target="_blank" className='footerNav'>
                        <div className='getStartedBtn py-3 px-5 mt-5 mt-md-0 cursor-pointer'>
                          <span className=''>{translate(props.lang, "Get Started")}</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center align-items-center py-5">
            <div className="col-11 col-md-10 col-lg-3 text-center text-lg-left mb-5">
              <img src={require('../../assets/img/footerLogo.png').default} className="img-fluid footerLogoSize mb-0 mb-lg-5" />

              <div className="pt-5 mt-5">
                <span className="footerTitleText font-EN-Medium">{translate(props.lang, "Contact VCG")}</span>

                <div className="d-flex justify-content-start align-items-center pt-4" style={{ borderBottom: "1px solid #fff" }}>
                  <Input
                    placeholder={translate(props.lang, "Enter Your Email Address")}
                    type="text"
                    // name="email"
                    // value={values.firstName}
                    // onChange={handleChange("firstName")}
                    className="footer-textBox py-3"
                  />
                  <div className="py-3 text-center">
                    <FaArrowRight fontSize={30} color="#fff" className="newsletterSubmitIcon cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-1 text-center text-md-left" />

            <div className="col-12 col-md-12 col-lg-7 text-center text-lg-left">
              <div className="row justify-content-center align-items-start">
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="">
                    <span className="footerTitleText font-EN-Medium">{translate(props.lang, "Company")}</span> <br /><br />
                    <a href="/company/about-us" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "About Us")}</span></a><br />
                    <a href="/company/advantages" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "Advantages")}</span></a><br />
                    <a href="/company/ai-powered" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "AI Powered")}</span></a>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="">
                    <span className="footerTitleText font-EN-Medium">{translate(props.lang, "Products")}</span> <br /><br />
                    <a href="/product/forex-exchange" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "Forex Exchange")}</span></a><br />
                    <a href="/product/precious-metal" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "Precious Metal")}</span></a><br />
                    <a href="/product/crude-oil" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "Crude Oil")}</span></a><br />
                    <a href="/product/index" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "Index")}</span></a><br />
                    <a href="/product/contract-for-different" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "Contract For Difference")}</span></a>
                  </div>
                  <div className="pt-4">
                    <span className="footerTitleText font-EN-Medium">{translate(props.lang, "Trading")}</span><br /><br />
                    <a href="/trading/vcg-pool" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "VCG World Pool")}</span></a><br />
                    <a href="/trading/account-type" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "Account Type")}</span></a>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-4">
                  <div className="">
                    <span className="footerTitleText font-EN-Medium">{translate(props.lang, "Tools")}</span> <br /><br />
                    <a href="/tools/market-news" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "Market News")}</span></a><br />
                    <a href="/tools/ctrader" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "CTrader")}</span></a>
                  </div>
                  <div className="pt-4">
                    <span className="footerTitleText font-EN-Medium">{translate(props.lang, "Support")}</span> <br /><br />
                    <a href="/support/contact-us" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "Contact Us")}</span></a><br />
                    <a href="/support/faq" className="footerNav"><span className="footerSubTitleText font-EN-Regular">{translate(props.lang, "FAQS")}</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center align-items-start">
            <div className="col-12 col-md-10 col-lg-3 text-center text-lg-left mb-4">
              <span className="copywriteText">{translate(props.lang, "© 2023 VCG GROUP @All right reserved")}</span>
            </div>
            <div className="col-12 col-md-12 col-lg-1 text-center text-md-left" />

            <div className="col-12 col-md-10 col-lg-7 text-left mb-4">
              <div className="d-block d-md-flex justify-content-center justify-content-lg-start align-items-center text-center">
                <a href="https://www.facebook.com/vcgroupfx" target="_blank" className="footerNav"><p className="footerTitleText font-EN-Medium mx-4 cursor-pointer">Facebook</p></a>
                <a href="https://www.instagram.com/vcgroupfx" target="_blank" className="footerNav"><p className="footerTitleText font-EN-Medium mx-4 cursor-pointer">Instagram</p></a>
                <a href="https://t.me/vcgroupfx_info" target="_blank" className="footerNav"><p className="footerTitleText font-EN-Medium mx-4 cursor-pointer">Telegram</p></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(DarkFooter);
